import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import { Player, Game, AuthResponse } from "./type";

import { Endpoints } from "../../../const/Api";
import { client } from "../../../tool/client";
import { RootState } from "../../../store";
import { LangKeys } from "../../../const/LangKeys";
import {
  changeAmount,
  changeMines,
  changeMode,
  setLastBet,
  startBet,
  updateTotalProfit,
} from "../../betbar/reducer";
import { loadLastPath } from "../../result/reducer";
import { ResultResponse } from "../../result/type";
import { changeRound } from "../../history/reducer";
import { formatCurrency } from "../../../tool/money";
import { useTranslation } from "react-i18next";

interface AuthState {
  player: Player | null;
  game: Game | null;

  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null | undefined;
}
let domain: any;
export const authLogin = createAsyncThunk(
  "auth/login",
  async (_, { dispatch }) => {
    domain = domain || (await (await fetch(Endpoints.Domain)).json());

    const response = await client.get(domain.auth, {});

    const data = response.data.data as AuthResponse;
    const { player, game } = data;

    let active_chip = 0;
    if (game.chip_base.length > 0) {
      active_chip = game.chip_base[0];
    }

    const { player_language, last_bet } = player;

    // cek valid language
    let valid_language = player_language.toLowerCase();
    if (LangKeys.indexOf(valid_language) === -1) {
      valid_language = "id";
    }

    // add last bet to player bet

    const payload = {
      game: {
        ...game,

        volume: {
          effect: 100,
          music: 100,
        },
        active_chip,
        popup_message: "",
        active_menu: null,
      },
      player: {
        ...player,

        player_language: valid_language,
      },
    };

    // const { t, i18n } = useTranslation();
    // const language = i18n.language;

    const handleFormatCurrency = (amount: number): string => {
      return formatCurrency(
        amount,
        payload.player.player_currency,
        payload.player.player_language
      );
    };

    // @ts-ignore
    if (
      last_bet &&
      // @ts-ignore
      last_bet.length != 0 &&
      last_bet.state == "initial"  &&
      last_bet.path === null &&
      // @ts-ignore
      last_bet.prize === 1 && 
      last_bet.mines === 25

    ) {
      dispatch(changeRound(Number(last_bet.round_id + 1)));

      return payload;

    }


    // @ts-ignore
    if (
      last_bet &&
      // @ts-ignore
      last_bet.length != 0 &&
      last_bet.state == "cashout" &&
      last_bet.path === null &&
      // @ts-ignore
      last_bet.prize === 1 && 
      last_bet.mines === 1
    ) {
      dispatch(changeRound(Number(last_bet.round_id + 1)));

    return payload;


    }

    // @ts-ignore
    if (
      last_bet &&
      // @ts-ignore
      last_bet.length != 0 &&
      last_bet.state == "initial" &&
      last_bet.path === null
    ) {
      dispatch(changeRound(Number(last_bet.round_id)));
      dispatch(changeMode("manual"));
      dispatch(changeAmount(last_bet.amount));
      let interval = setInterval(() => {
        // @ts-ignore
        if (
          document.querySelector("input#amount") &&
          // @ts-ignore
          document.querySelector("input#amount")!.value !==
          handleFormatCurrency(last_bet.amount)
        ) {
          // @ts-ignore
          document.querySelector("input#amount")!.value = handleFormatCurrency(
            last_bet.amount
          );
          dispatch(updateTotalProfit(0));

          clearInterval(interval);
        }
      }, 500);
      dispatch(changeMines({ mode: "manual", value: last_bet.mines }));
      const payload: any = {
        ...last_bet,
        last_bet: {
          game: "GH000001",
          total_amount: last_bet.amount,
          button_bet: {
            amount: last_bet.amount,
            mines: last_bet.mines,
            path: null,
            round_id: last_bet.round_id,
            state: "initial",
          },
          round_id: last_bet.round_id,
        },
      };
      payload.mines = null;
      // payload.path = payload.path ? payload.path.split(","): [];
      dispatch(startBet());
      dispatch(loadLastPath(payload));
    }

    // @ts-ignore
    if (
      last_bet &&
      // @ts-ignore
      last_bet.length != 0 &&
      last_bet.state == "sweep" &&
      last_bet.path &&
      last_bet.path.length
    ) {
      dispatch(changeRound(Number(last_bet.round_id)));
      dispatch(changeMode("manual"));
      dispatch(changeAmount(last_bet.amount));
      let interval = setInterval(() => {
        // console.log("check load lastbet");
        // @ts-ignore
        if (
          document.querySelector("input#amount")) {

          // @ts-ignore
          if (

            // @ts-ignore
            document.querySelector("input#amount")!.value !==
            handleFormatCurrency(last_bet.amount)
          ) {

            // @ts-ignore
            document.querySelector("input#amount")!.value = handleFormatCurrency(
              last_bet.amount
            );

          }
          // @ts-ignore
          if (last_bet.prize) {
            // @ts-ignore
            if (document.querySelector("input#total_profit")) {
              // @ts-ignore
              document.querySelector("input#total_profit")!.value =
                // @ts-ignore
                handleFormatCurrency(last_bet.amount * last_bet.prize);

            }
            // @ts-ignore
            dispatch(updateTotalProfit(last_bet.amount * last_bet.prize));
          }
          clearInterval(interval);
        }
      }, 500);

      dispatch(changeMines({ mode: "manual", value: last_bet.mines }));
      const payload: any = {
        ...last_bet,
        last_bet: {
          game: "GH000001",
          total_amount: last_bet.amount,
          button_bet: {
            amount: last_bet.amount,
            mines: last_bet.mines,
            path: last_bet.path[last_bet.path.length - 1],
            round_id: last_bet.round_id,
            state: "sweep",
          },
          round_id: last_bet.round_id,
        },
      };
      payload.mines = null;
      // payload.path = payload.path ? payload.path.split(","): [];
      dispatch(startBet());
      dispatch(changeAmount(last_bet.amount));
      const interCont = setInterval(() => {
        console.log("loadLastPath")
        if (!window.container || (window.container && !window.container[25])) {
          return
        }
        dispatch(loadLastPath(payload));
        clearInterval(interCont)
      }, 500);
    }
    return payload;
  }
);

const initialState: AuthState = {
  player: null,
  game: null,

  status: "idle",
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state, action: PayloadAction) => {
      state = initialState
    },
    updateBalance: (state, action: PayloadAction<number>) => {
      if (state.player) {
        state.player.player_balance += action.payload;
      }
    },
    setPopupMessage: (state, action: PayloadAction<string>) => {
      if (state.game) {
        state.game.popup_message = action.payload;
      }
    },
    setActiveChip: (state, action: PayloadAction<number>) => {
      if (state.game) {
        state.game.active_chip = action.payload;
      }
    },
    setActiveMenu: (state, action: PayloadAction<string | null>) => {
      if (state.game) {
        state.game.active_menu = action.payload;
      }
    },
    setMusic: (state, action: PayloadAction<boolean>) => {
      if (state.game) {
        client.post(Endpoints.Settings, {
          effect: state.game.sounds.effect,
          music: action.payload,
          language: (state.player?.player_language || "").toUpperCase(),
        });

        state.game.sounds.music = action.payload;
      }
    },
    setEffect: (state, action: PayloadAction<boolean>) => {
      if (state.game) {
        client.post(Endpoints.Settings, {
          effect: action.payload,
          music: state.game.sounds.music,
          language: (state.player?.player_language || "").toUpperCase(),
        });

        state.game.sounds.effect = action.payload;
      }
    },
    setVolumeMusic: (state, action: PayloadAction<number>) => {
      if (state.game) {
        state.game.volume.music = action.payload;
      }
    },
    setVolumeEffect: (state, action: PayloadAction<number>) => {
      if (state.game) {
        state.game.volume.effect = action.payload;
      }
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      if (state.player && state.game) {
        const sendLang = action.payload.toUpperCase();

        client.post(Endpoints.Settings, {
          ...state.game.sounds,

          language: sendLang,
        });

        state.player.player_language = action.payload;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authLogin.pending, (state, action: PayloadAction) => {
        state.status = "loading";
      })
      .addCase(
        authLogin.fulfilled,
        (state, action: PayloadAction<{ player: Player; game: Game }>) => {
          state.status = "succeeded";
          state.game = action.payload.game;
          state.player = action.payload.player;
        }
      )
      .addCase(authLogin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  updateBalance,
  setPopupMessage,
  setActiveChip,
  setActiveMenu,
  setMusic,
  setEffect,
  setVolumeMusic,
  setVolumeEffect,
  setLanguage,
  resetAuth
} = authSlice.actions;

export default authSlice.reducer;

export const getPlayer = (state: RootState) => state.auth.player;
export const getGame = (state: RootState) => state.auth.game;
