import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { Endpoints } from "../../const/Api";
import { RootState } from "../../store";
import { client } from "../../tool/client";
import { resetResult, resultFinished, sendBetAction } from "../result/reducer";
import { restartAuto } from "../game/reducer";
import { History, HistoryFull } from "./type";

interface InitialState {
  current_round: number;

  status: "idle" | "loading" | "succeeded" | "failed";
  status_refresh: "idle" | "loading" | "succeeded" | "failed";
  error: string | null | undefined;
  data: HistoryFull[];
  last_history: History[];
  viewAll: boolean;
}

const historyAdapter = createEntityAdapter<History>({
  selectId: (history) => history.round_id,
  sortComparer: (a, b) => b.round_id - a.round_id,
});

const initialState: InitialState = {
  current_round: 1,
  status: "idle",
  status_refresh: "idle",
  error: null,
  data: [],
  last_history: [],
  viewAll: false,
};
let domain: any
export const fetchHistory = createAsyncThunk(
  "history/fetchHistory",
  async () => {
    domain = domain || await (await fetch(Endpoints.Domain)).json();
    const response = await client.get(domain.history);

    if (!response.data.data) {
      return Promise.reject("data history empty");
    }

    return response.data.data;
  }
);
let i: any, v: any;
export const refreshHistory = createAsyncThunk(
  "history/refreshHistory",
  async (_, { dispatch, getState }: any) => {
    // console.log(state.playerbet.currMode == "auto" , state.playerbet.auto.numberOfBet , state.playerbet.auto.start);


    const domain = await (await fetch(Endpoints.Domain)).json();
    const response = await client.get(domain.history);

    if (!response.data.data) {
      return Promise.reject("data history empty");
    }

    return response.data.data;
  }
);

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    addedHistory: (state, action: PayloadAction<History>) => {
      // historyAdapter.addOne(state, action.payload)
      // state.data = [...state.data, action.payload]
    },
    resetHistory: (state, action: PayloadAction) => {
      state = initialState;
    },
    addedLastHistory: (state, action: PayloadAction<History>) => {
      state.last_history = [...state.last_history, action.payload];
      // historyAdapter.addOne(state, action.payload)
    },
    clearLastHistory: (state, action: PayloadAction) => {
      // historyAdapter.addOne(state, action.payload)
      state.last_history = [];
    },
    closeViewAll: (state, action: PayloadAction) => {
      state.viewAll = false;
    },
    openViewAll: (state, action: PayloadAction) => {
      state.viewAll = true;
      // state.viewAll = !state.viewAll ;
    },
    newRound: (state, action: PayloadAction) => {
      state.current_round = state.current_round + 1;

      // state.viewAll = !state.viewAll ;
    },
    changeRound: (state, action: PayloadAction<number>) => {
      state.current_round = action.payload;

      // state.viewAll = !state.viewAll ;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchHistory.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(
        fetchHistory.fulfilled,
        (state, action: PayloadAction<HistoryFull[]>) => {
          state.status = "succeeded";
          state.status_refresh = "succeeded";

          const lastHistory = action.payload[0];
          if (lastHistory) {
            state.current_round = Number(lastHistory.round_id) + 1;
          }

          state.data = action.payload;
        }
      )
      .addCase(fetchHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(refreshHistory.pending, (state, action) => {
        state.status_refresh = "loading";
      })
      .addCase(refreshHistory.rejected, (state, action) => {
        state.status_refresh = "failed";
      })


      .addCase(
        refreshHistory.fulfilled,
        (state, action: PayloadAction<HistoryFull[]>) => {
          state.status_refresh = "succeeded";

          // const lastHistory = action.payload[0];
          // if (lastHistory) {
          //   if (window.location.hostname === "localhost") {
          //     state.current_round = Number(state.current_round) + 1;
          //   } else state.current_round = Number(lastHistory.round_id) + 1;
          // }

          state.data = action.payload;
        }
      );
  },
});

export const {
  addedHistory,
  clearLastHistory,
  addedLastHistory,
  openViewAll,
  closeViewAll,
  newRound,
  changeRound,
  resetHistory
} = historySlice.actions;

export default historySlice.reducer;
