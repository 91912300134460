
import Bom from './bomb.webp'
import BombIcon from './Symbol_Bomb.webp'


const Bomb = [
    BombIcon,
    Bom,
 
];

export default  Bomb