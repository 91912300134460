
import Bom from './diamond.webp'
import Icon from './Symbol_5.webp'

const Dimaond5 = [
    Icon,
    Bom,
 
];

export default  Dimaond5