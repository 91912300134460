import React, { useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import BGBarPortrait from "./bet-background-mobile.webp";
import BGMode from "./bg-mode.webp";
import BGModeActive from "./bg-mode-active.webp";
import BGInput12 from "./bg-input-12.webp";
import BGInput6 from "./bg-input-6.webp";
import InfinityIcon from "./∞.png";
import Up from "./up.webp";
import Submit from "./btn-bet.webp";
import Stop from "./btn-stop-bet.webp";
import BTNRandom from "./btn-random-tile.webp";
import Gems from "./diamond.webp";
import Bomb from "./bomb.webp";

import BGBarLandscape from "./bar-form-landscape.webp";
import BGRect from "./rect.webp";
import "./index.scss";
import DropDown from "./dropdown";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { getPlayer } from "../user/auth/reducer";
import { LimitBetKeys, Player } from "../user/auth/type";
import { formatCurrency } from "../../tool/money";
import useOrientation from "../hook/Orientation";
import { changeAmount, changeMines, changeMode, changeNumOfBet, startBet, stopBet, updateTotalProfit } from "./reducer";
import {
  endAuto,
  resetResult,
  resultFinished,
  // sendAutoBetAction,
  sendBetAction,
  showError,
  startAuto,
} from "../result/reducer";
import { clearLastHistory, newRound } from "../history/reducer";
import { restart, restartAuto } from "../game/reducer";
import { SendBet } from "../result/type";

import IconUser from "./../setting/ic-user.webp";
import IconWallet from "./../setting/ic-wallet.webp";
import { debounce } from "../../tool/debounce";

let autotimeout: any



/**
 * 
 * 
 *
 * 
 * 
{
    "mines": 8,
    "path": null | last position | all wanna open position | all open possition, 
    "round_id": 5,
    "state": initial | sweep | auto | cashout
  }
  
  
  */
const BetBar = () => {

  const orientation = useOrientation();
  const dispatch = useAppDispatch();
  const playerbet = useAppSelector((state) => state.playerbet);
  const result = useAppSelector((state) => state.result);
  const history = useAppSelector((state) => state.history);
  const game = useAppSelector((state) => state.game);
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  // console.log(game.sele);
  const limit_bet = useAppSelector(
    (state) => state.auth.game?.limit_bet
  ) as Record<LimitBetKeys, number>;



  const ballList = playerbet.auto.mines_list;

  const player = useAppSelector(getPlayer) as Player;

  const status = useAppSelector((state) => state.result!.status);
  const [insuficient, setInsuficient] = useState(false);
  const [delayOnBet, setDelayOnBet] = useState(false);
  const [initial, setInitial] = useState(false);

  // useEffect(()=>{
  //   if(playerbet.manual.start || playerbet.auto.start) setDisable(true);
  // }, [playerbet.manual.start, playerbet.auto.start])

  useEffect(() => {
    if (status === "force-end-spin") {
      // handleStopAutoBet()
      dispatch(stopBet());
    }
  }, [status]);


  const { player_balance, player_name, player_currency } = player;

  const handleFormatCurrency = (amount: number): string => {
    return formatCurrency(amount, player_currency, language);
  };

  const defaultAmount = Number(limit_bet.minimal);
  const handleFormatAmount = (value: string = "") => {
    const newAmount = Number(
      value.split(".").join("").split("Rp").join("").split(",").join(".")
    ).toString();

    return newAmount;
  };

  const amountRef = useRef();

  const handleDecAmount = () => {
    // @ts-ignore
    if (amountRef.current.disabled) return;

    const newAmount =
      // @ts-ignore
      Number(handleFormatAmount(amountRef.current!.value)) * 0.5 >=
        limit_bet.minimal
        ? // @ts-ignore
        Number(handleFormatAmount(amountRef.current!.value)) * 0.5
        : Number(handleFormatAmount(limit_bet.minimal.toString()));
    // @ts-ignore
    amountRef.current!.value = handleFormatCurrency(newAmount);

    dispatch(
      // @ts-ignore
      changeAmount(Number(handleFormatAmount(amountRef.current!.value)))
    );
  };

  const handleIncAmount = () => {
    // @ts-ignore
    if (amountRef.current.disabled) return;
    const newAmount =
      // @ts-ignore
      Number(handleFormatAmount(amountRef.current!.value)) * 2 <=
        limit_bet.maximal
        ? // @ts-ignore
        Number(handleFormatAmount(amountRef.current!.value)) * 2
        : Number(handleFormatAmount(limit_bet.maximal.toString()));

    // @ts-ignore
    amountRef.current!.value = handleFormatCurrency(newAmount);
    // @ts-ignore
    dispatch(
      // @ts-ignore
      changeAmount(Number(handleFormatAmount(amountRef.current!.value)))
    );
  };



  const [mode, setMode] = useState<keyof typeof modes>("manual");
  
  const [stoping, setStoping] = useState(false);

  const handleMinesChange = (mines: string | number) => {
    if (playerbet.manual.start || playerbet.auto.start) return;
    dispatch(changeMines({ mode, value: Number(mines) }));
  }

  const modes = {
    "auto": "",
    "manual": ""
  }

  useEffect(() => {
    if (game.isOpenBomb) {
      setDelayOnBet(true)
      setTimeout(() => {
        setDelayOnBet(false);
      }, 2000);
    }
  }, [game.isOpenBomb])

  useEffect(() => {
    try {
      // console.log(result.payload)
      if ((result.status==="succeeded" || result.status==="failed") && result.payload.last_bet.button_bet.state=="initial") {
        setTimeout(() => {
          setInitial(false);
        }, 500);
      }
    } catch (error) {
      
    }
  }, [result.status, result.payload])



  // const [on_win_mode, setOnWinMode] = useState<"reset" | "increase-by">("reset");
  // const [on_loss_mode, setOnLossMode] = useState<"reset" | "increase-by">("increase-by");

  useEffect(() => {
    dispatch(changeMode(mode));
    if (!result.loadLastBet) {

      dispatch(resetResult());
      dispatch(restart());
    }
    // @ts-ignore
    // dispatch(changeAmount(Number(handleFormatAmount(amountRef.current!.value))));

    if (amountRef.current && playerbet[mode].amount) {
      // @ts-ignore
      amountRef.current!.value = handleFormatCurrency(playerbet[mode].amount);
      const changeEvent = new Event("change");
      // @ts-ignore
      amountRef.current!.dispatchEvent(changeEvent);
    }
  }, [mode])

  useEffect(() => {
    // @ts-ignore
    const val = amountRef.current?.value;
    // @ts-ignore
    if (!Number.isNaN(Number(handleFormatAmount(val)))) {
      dispatch(changeAmount(Number(handleFormatAmount(val))));
    } // @ts-ignore
  }, [amountRef, amountRef.current, amountRef.current?.value]);


  const handleCashOut = () => {
    if (delayOnBet) return;
    const path: number[] = [];
    Object.keys(window.container).map((idxChest)=>{
      if(window.container[idxChest].list[1].texture.key=="chest-open"){
        // @ts-ignore
        path.push(Number(idxChest));
      }
    })
    const sendBet: SendBet = {
      game: "GH000001",
      total_amount: playerbet.manual.amount,
      button_bet: {
        amount: playerbet.manual.amount,
        mines: playerbet.manual.mines,
        path: path.join(","),
        round_id: Number(history.current_round),
        state: "cashout"
      },
      round_id: Number(history.current_round)
    };
    dispatch(sendBetAction(sendBet));
    dispatch(stopBet());
    setTimeout(() => {
      setDelayOnBet(false);
    }, 2000);
    setDelayOnBet(true);
    setInitial(true);
    setTimeout(() => {
      setInitial (false);
    }, 500);
  }


  const handleBet = () => {
      if (player_balance <(playerbet.manual.amount || defaultAmount)) {
       return dispatch(showError("BALANCE NOT ENOUGH"));
      }
    
      if (window.bgProfit) return;
    if (initial) return;
     
    const sendBet: SendBet = {
      game: "GH000001",
      total_amount: playerbet.manual.amount || defaultAmount,
      button_bet: {
        amount: playerbet.manual.amount || defaultAmount,
        mines: playerbet.manual.mines,
        path: null,
        round_id: Number(history.current_round),
        state: "initial"
      },
      round_id: Number(history.current_round)
    };
    dispatch(resetResult());

    dispatch(sendBetAction(sendBet));
    
    // setDelayOnBet(true);
    // // dispatch(newRound())

    //   setTimeout(() => {
    //     setDelayOnBet(false);
    //   }, 500);
  };

  const handleAutoBet = () => {
    if (!game.selected.length) {

      dispatch(resetResult());
      dispatch(showError("Pilih box dahulu!"));
    }

    if (!game.selected.length || playerbet.auto.start)
      return;
    dispatch(resultFinished());

    dispatch(restartAuto());
    dispatch(resetResult());

    dispatch(startBet())

    const sendBet: SendBet = {
      game: "GH000001",
      total_amount: playerbet.auto.amount || defaultAmount,
      button_bet: {
        amount: playerbet.auto.amount || defaultAmount,
        mines: playerbet.auto.mines,
        path: game.selected.join(","),
        round_id: Number(history.current_round),
        state: "auto"
      },
      round_id: Number(history.current_round)
    };
    dispatch(sendBetAction(sendBet));

  };

  const handleStopAutoBet = () => {
    if (stoping) return;
    // dispatch(resetResult());

    dispatch(stopBet());
    setStoping(true);

  };

  const user = useAppSelector(getPlayer) as Player;

  useEffect(() => {

    if (playerbet.currMode == "auto" && !playerbet.auto.numberOfBet && playerbet.auto.start) {
      handleStopAutoBet();
    }
  }, [playerbet.auto.numberOfBet, playerbet.currMode]);

  useEffect(() => {
    if (!playerbet.auto.start && stoping && (result.status == "idle" || result.status == "reset")) {
      setTimeout(() => {
        setStoping(false);
      }, 2000);
    }

  }, [playerbet.auto.start, stoping, result.status])

  useEffect(() => {
    if (playerbet[mode].amount * playerbet[mode].mines > user.player_balance) {
      setInsuficient(true);
    } else {
      setInsuficient(false);
    }
  }, [playerbet[mode].amount * playerbet[mode].mines, user.player_balance]);


  const displayPlayerBalance = useMemo(
    () => formatCurrency(player_balance, player_currency, language),
    [player_balance, player_currency, language]
  );

  const lengthInfo = player_name.length + displayPlayerBalance.length;
  const current_round = useAppSelector(
    (state) => state.history.current_round
  ) as number;

  const modesComponent: any = {
    Auto: () => {
      return <>
        <DropDown
          key={"bet-form-mines-auto"}
          className={"bet-form-mines"}
          label={t("betbar.mines")}
          options={ballList}
          setSelected={handleMinesChange}
          defaultValue={playerbet.auto.mines}
          disabled={playerbet.auto.start}
        />
        <div className="form-group number_of_bets">
          <img
            className="bet-bg-input"
            alt=""
            src={BGInput12}
          />
          <label htmlFor="numberOfBet">{t("betbar.number_of_bets")}</label>
          <input
            //@ts-ignore
            // type="number"
            // id="amount"
            // name="amount"
            // value={playerbet.auto.numberOfBet}
            // onChange={(e)=>{
            //   if(!Number.isNaN(e.target.value))
            //   dispatch(changeNumOfBet(Number(e.target.value)));
            //   else e.target.value = "0";
            // }}

            //@ts-ignore
            // ref={amountRef}
            type="text"
            id="numberOfBet"
            name="numberOfBet"
            maxLength={7}
            disabled={playerbet[mode].start}
            value={playerbet.auto.numberOfBet}

            // value={(()=>{
            //   const newVal = playerbet[mode].amount < defaultAmount?defaultAmount:playerbet[mode].amount;
            //   dispatch(
            //     // @ts-ignore
            //     changeNumOfBet(newVal)
            //   );
            //                   return handleFormatCurrency(newVal);
            // })()}
            onKeyDownCapture={(e) => {
              var charTyped = e.key;
              var letterRegex = /[^0-9]/;
              if (!charTyped.match(letterRegex)) {
                // return e.preventDefault();

              }
              else if (e.key == "Backspace") {
              }
              else if (e.key.indexOf("Arrow") > -1) {
              } else {
                return e.preventDefault();
              }

            }}
            onChange={(e) => {
              if (e.target.value !== "Infinity" && Number.isNaN(Number(e.target.value))) return;
              dispatch(
                // @ts-ignore
                changeNumOfBet(Number(e.target.value))
              );

              if (Number(e.target.value) < 0) {
                // e.target.value= "0"
                changeNumOfBet(Number(0));
              }
            }}
            onFocus={({ target }) => {
              if (target.value === "Infinity" || target.value === "NaN") {
                target.value = "0";
              }
            }}
            onBlur={({ target }) => {
              dispatch(
                changeNumOfBet(Number(target.value))
              );
            }}
          />
          <div className="bet-form-tool-list number-of-bets">
            <div
              className="tool-item"
              onClick={() => {
                // handleDecAmount();
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {/* <img onContextMenu={(e)=>e.preventDefault()} draggable="false" className="bet-form-rect" alt="" src={Up} onClick={() => {
                if (!playerbet[mode].start)
                  dispatch(
                    changeNumOfBet(Number(playerbet.auto.numberOfBet) + 1)
                  );
              }} />
              <img onContextMenu={(e)=>e.preventDefault()} draggable="false" className="bet-form-rect" alt="" src={Up} style={{ transform: "rotate(180deg)" }} onClick={() => {
                if (Number(playerbet.auto.numberOfBet) - 1 >= 0)
                  if (!playerbet[mode].start)
                    dispatch(
                      changeNumOfBet(Number(playerbet.auto.numberOfBet) - 1)
                    );
              }} /> */}
              </div>
            </div>
            <div className="tool-item">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <img onContextMenu={(e) => e.preventDefault()} draggable="false" className="bet-form-rect" alt="" src={InfinityIcon} onClick={() => {
                  if (!playerbet[mode].start)
                    dispatch(
                      changeNumOfBet(Infinity)
                    );
                }} />
              </div>
            </div>
          </div>
        </div>

        {
          true ? (playerbet.auto.start || stoping ? <div className="form-group start-auto" onClick={handleStopAutoBet}>
            <img
              className="bet-button-bg"
              alt=""
              src={Stop}
            />
            <span className="button-text">{stoping ? t(`betbar.stoping_auto`) : t(`betbar.stop_auto`)}</span>
          </div>
            : <div className="form-group start-auto" onClick={handleAutoBet}>
              <img
                className="bet-button-bg"
                alt=""
                src={Submit}
              />
              <span className="button-text">{t(`betbar.start_auto`)}</span>
            </div>) : <></>
        }
      </>
    },
    Manual: () => {
      return <>


        {playerbet.manual.start ? <div className="form-group bet-form-on-win mines-gems">
          <div className="input">
            <div>
              <DropDown
                bg={BGInput6}
                toolicon={Bomb}
                key={"bet-form-mines-manual"}
                className={"bet-form-mines-manual"}
                label={t("betbar.mines")}
                options={ballList}
                setSelected={handleMinesChange}
                defaultValue={playerbet.manual.mines}
                disabled={playerbet.manual.start}
              />

            </div>

            <div>
              <label htmlFor="amount">{t("betbar.gems")}</label>

              <img
                className="bet-bg-input"
                alt=""
                src={BGInput6}
              />
              <input
                //@ts-ignore
                type="text"
                id="gems"
                name="gems"
                value={isNaN((25 - Number(playerbet[mode].mines) - Number(game.openCount))) ? 0 : (25 - Number(playerbet[mode].mines) - Number(game.openCount))}
                onFocus={(e) => e.target.blur()}
              />
              <div className="bet-form-tool-list">

                <div className="tool-item">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <img onContextMenu={(e) => e.preventDefault()} draggable="false" className="bet-form-rect" alt="" src={Gems} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : <DropDown
          key={"bet-form-mines-manual-small"}
          className={"bet-form-mines"}
          label={t("betbar.mines")}
          options={ballList}
          setSelected={handleMinesChange}
          defaultValue={playerbet.manual.mines}
          disabled={playerbet.manual.start}
        />}
        {playerbet.manual.start && !game.isOpenBomb ? <div className="form-group bet-form-bet total-profit">
          <img
            className="bet-bg-input"
            alt=""
            src={BGInput12}
          />
          <label htmlFor="total_profit">{t("betbar.total_profit")} : {result.payload && result.payload.prize ? result.payload.prize.toFixed(5) : ""}</label>
          <input
            //@ts-ignore
            // ref={amountRef}
            type="text"
            id="total_profit"
            name="total_profit"
            // maxLength={7}
            value={handleFormatCurrency(playerbet.manual.total_profit || (result.payload && result.payload.prize ? result.payload.prize.toFixed(5)*result.payload.amount : 0))}
            disabled
          />
          <div className="bet-form-tool-list"></div>
        </div> : <></>}
        {true ? (<>{playerbet.manual.start && !game.isOpenBomb && !delayOnBet? <div className="form-group start-auto random" onClick={() => {
         
         
         let target = Object.keys(window.container)[Math.floor(Math.random() * Object.keys(window.container).length)];
          while (window.container[target].list[1].texture.key !== "chest-close") {
             target = Object.keys(window.container)[Math.floor(Math.random() * Object.keys(window.container).length)];
          }
          // @ts-ignore
          window.container[target].click();
        }}
        >
          <img
            className="bet-button-bg"
            alt=""
            src={BTNRandom}
          />
          <span className="button-text">{t(`betbar.pick_random`)}</span>
        </div> : <></>}
          {playerbet.manual.start && game.openCount > 0 && !game.isOpenBomb && !delayOnBet? <div className="form-group start-auto cash_out" onClick={() => handleCashOut()} style={{ marginTop: '2rem', }}>
            <img
              className="bet-button-bg"
              alt=""
              src={Submit}
            />
            <span className="button-text">{t(`betbar.cash_out`)}</span>
          </div> : <></>}

          {!playerbet.manual.start ? <div key={'handleBet'.concat(`${(playerbet.manual.amount || defaultAmount)}`)} className="form-group start-auto cash_out" onClick={debounce(()=>handleBet(), 1000)}>
            <img
              className="bet-button-bg"
              alt=""
              style={{ filter: initial || window.bgProfit ? 'grayscale(1)' : 'none' }}
              src={Submit}
            />
            <span className="button-text">{t(`betbar.start`)}</span>
          </div> : <></>}</>) : <></>}
      </>
    }

  }


  return (
    <div className="bet-wrapper">
      <div className="bet">

        <img
          className="bet-bg"
          alt=""
          src={orientation == "landscape" ? BGBarLandscape : BGBarPortrait}
        />
        <div className={"bet-form ".concat(mode)}>
          <div className="form-group mode" >
            <img
              className="bet-bg-mode"
              alt=""
              src={BGMode}
            />

            <div className="mode-container">
              <div className="mode-overlay" onClick={() => {
                if (mode == "manual" && playerbet[mode].start) return;
                if (mode == "auto" && playerbet[mode].start) return;
                setMode((old) => old === "manual" ? "auto" : "manual");
              }}></div>

              <img
                className={"bet-bg-mode-active ".concat(mode === "auto" ? "auto" : "")}
                alt=""
                src={BGModeActive}
              />
              <span>Manual</span>
              <span>Auto</span>
            </div>

          </div>
          <div className="form-group bet-form-bet" style={{ marginTop: '-6rem', }}>
            <img
              className="bet-bg-input"
              alt=""
              src={BGInput12}
            />
            <label htmlFor="amount">{t("betbar.bet_amount")}</label>
            <input
              //@ts-ignore
              ref={amountRef}
              type="text"
              id="amount"
              name="amount"
              maxLength={7}
              disabled={playerbet[playerbet.currMode].start}
              defaultValue={handleFormatCurrency(defaultAmount)}
              onKeyDownCapture={(e) => {
                const charTyped = e.key;
                const letterRegex = /[^0-9]/;
                if (!charTyped.match(letterRegex)) {
                  try {
                    if (
                      // @ts-ignore
                      e.target.value.split(".")[1].length >= 2 &&
                      // @ts-ignore
                      window.getSelection().getRangeAt(0).startOffset ==
                      // @ts-ignore
                        e.target.value.length
                    ) {
                      return e.preventDefault();
                    }
                  } catch (error) {}
                } else if (e.key == "Backspace") {
                } else if (e.key.indexOf("Arrow") > -1) {
                } else if (e.key == ".") {
                  // @ts-ignore
                  if (e.target.value.indexOf(".") > 0) {
                    return e.preventDefault();
                  }
                } else {
                  return e.preventDefault();
                }
              }}
              onChange={(e) => {
                if (Number.isNaN(Number(e.target.value))) return;
                dispatch(
                  // @ts-ignore
                  changeAmount(Number(handleFormatAmount(e.target.value)))
                );
              }}
              onFocus={({ target }) => {
                target.value = playerbet[playerbet.currMode].amount.toString();
              }}
              onBlur={({ target }) => {
                if (limit_bet.minimal >= Number(target.value)) {
                  target.value = handleFormatCurrency(
                    Number(limit_bet.minimal)
                  );
                } else if (limit_bet.maximal <= Number(target.value)) {
                  target.value = handleFormatCurrency(
                    Number(limit_bet.maximal)
                  );
                } else {
                  target.value = handleFormatCurrency(Number(target.value));
                }

                dispatch(
                  changeAmount(Number(handleFormatAmount(target.value)))
                );
              }}
            />
            <div className="bet-form-tool-list">
              <div
                className="tool-item"
                onClick={() => {
                  handleDecAmount();
                }}
              >
                <span>1/2</span>
                <img onContextMenu={(e) => e.preventDefault()} draggable="false" className="bet-form-rect" alt="" src={BGRect} />
              </div>
              <div className="tool-item" onClick={() => handleIncAmount()}>
                <span className="x2">2x</span>
                <img onContextMenu={(e) => e.preventDefault()} draggable="false" className="bet-form-rect" alt="" src={BGRect} />
              </div>
            </div>
          </div>
          {
            modesComponent[mode.charAt(0).toUpperCase() + mode.slice(1)]()
          }
        </div>

        <div className={`header-info${lengthInfo > 30 ? " header-info-sm" : ""}`}>
          <div className="header-info-content">
            <img onContextMenu={(e) => e.preventDefault()} draggable="false" className="header-info-icon" alt="" src={IconUser} />

            <span className="header-info-user-value">{player_name}</span>
          </div>

          <div className="header-info-content">
            <img onContextMenu={(e) => e.preventDefault()} draggable="false" className="header-info-icon" alt="" src={IconWallet} />

            <span className="header-info-balance-value">
              {displayPlayerBalance}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetBar;
