import i18n from 'i18next'
import { initReactI18next } from "react-i18next"
import id from './id.json'

const resources = {
    id: {
        translation: id
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "id",
        interpolation: {
        escapeValue: false
        }
    });

export default i18n