
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import ChestClose from './chest-close.webp'
import ChestOpen from './chest-open.webp'
import { images, randomProperty } from '.'
import Bomb from './animation/Symbol Bomb'
import { gsap, Linear } from 'gsap'
import { useAppDispatch, useAppSelector } from '../../store/hook'
import { openBomb } from './reducer'
import { countOpen } from './reducer'
import { stopBet } from '../betbar/reducer'
import { sendBetAction } from '../result/reducer'
import { SendBet } from '../result/type'
import sound from '../../sound'

const defaultImageProps = {
    draggable: false,
    onContextMenu: (e: any) => e.preventDefault()
}

type ContentStateType = "hide" | "bomb" | "diamond" | "start";
export const Chest2 = ({ dataProps, idxChest, handleClick, selected, idxRow }: any) => {

    const [open, setOpen] = useState(false);
    const [contentState, setContentState] = useState<ContentStateType>("hide");
    const [shadowOpen, setShadowOpen] = useState(false);



    // const [ isSelect, setIsSelect ] = useState(false);
    const sym = randomProperty(images);
    const ref: any = useRef(null);


    useEffect(() => {
        if (dataProps) {
            const result = dataProps;
            if (result.payload.mines && result.payload.path && result.payload.path.includes(idxChest.toString()) && result.payload.mines.includes(idxChest.toString())) {
                setShadowOpen(false);
                setContentState("bomb");

            }
            if (result.payload.path && result.payload.path.includes(idxChest.toString()) && (!result.payload.mines || (result.payload.mines && !result.payload.mines.includes(idxChest.toString())))) {
                setShadowOpen(false);
                setContentState("diamond");
            }
            if (result.payload.mines && result.payload.path && !result.payload.path.includes(idxChest.toString()) && result.payload.mines.includes(idxChest.toString())) {
                setShadowOpen(true);
                setContentState("bomb");
            }
            if (result.payload.mines && result.payload.path && !result.payload.path.includes(idxChest.toString()) && !result.payload.mines.includes(idxChest.toString())) {
                setShadowOpen(true);
                setContentState("diamond");
            }
            setOpen(true);
        }
    }, []);



    return <div key={`${dataProps ? "history" : "main"}-col-box-${idxChest}`} className="col">
        <div data-idxchest={idxChest} className={"chest chest-".concat(idxChest.toString()).concat(" ").concat(selected && !open ? " selected " : "").concat(!dataProps && selected && open && !shadowOpen ? " open " : "").concat(((!dataProps && !selected) || dataProps) && open && shadowOpen ? " shadowOpen " : "")} onClick={handleClick} >
            <img ref={ref} className="box" src={open ? ChestOpen : ChestClose} alt="box"  {...{ ...defaultImageProps }} />
            {!["hide", "start"].includes(contentState) ? <Content {...{ contentState, sym, shadowOpen }} /> : <></>}
        </div>
    </div>
}

interface ContentIProps {
    contentState: ContentStateType;
    sym: ReturnType<typeof randomProperty>;
    shadowOpen: boolean
}

const Content: React.FC<ContentIProps> = ({ contentState, sym, shadowOpen }) => {

    const src = contentState == "bomb" ? Bomb : images[sym];
    const ref: any = useRef(null);
    useLayoutEffect(() => {
        try {
            if (ref.current) {

                const obj = { curImg: 1 };
                if (contentState == "bomb" && shadowOpen) {
                    ref.current!.src = Bomb[0];

                }

                const tween = gsap.to(obj, 1,
                    {
                        curImg: src.length - 1,	// animate propery curImg to number of images
                        roundProps: "curImg",				// only integers so it can be used as an array index
                        repeat: contentState != "bomb" ? 0 : Infinity,
                        immediateRender: true,			// load first image automatically
                        ease: Linear.easeInOut,			// show every image the same ammount of time
                        onStart: function () {
                            if (ref.current) {

                                ref.current.previousSibling.style.position = "absolute";
                            }
                            if (ref.current) {
                                if (contentState == "bomb" && shadowOpen) {
    
                                } else {
                                    if (shadowOpen) {
    
                                        ref.current!.src = src[src.length - 1];
                                    } else {
    
                                        ref.current!.src = src[obj.curImg];
                                    }
    
                                }
                            }
                        },
                        onUpdate: function () {
                        },
                        onComplete: function () {

                        }

                    }
                );
                return () => {
                    tween.kill()
                }

            }
        } catch (error) {

        }
    }, []);

    return <>
        <img ref={ref} className="content" src={""} alt="content"  {...{ ...defaultImageProps }} />
        {/* {contentState == "bomb" ? <img className="content bomb" src={Bomb[0]} alt="content"   {...{ ...defaultImageProps }} /> : <></>} */}
    </>
}