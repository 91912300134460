import { useLayoutEffect, useState } from "react";


const useOrientation = ()=>{

    const handleQuery = ()=>document.querySelector('.game-area.landscape') ? 'landscape':'portrait';
    const [ orientation, setOrientation ] = useState(handleQuery());

    useLayoutEffect(()=>{
        const handleResize = () => {
            setOrientation(handleQuery())
        }
        handleResize();
        window.addEventListener('resize',handleResize)
        // let oldHanddler = window.onorientationchange
        window.onorientationchange = handleResize;
        document.addEventListener("orientationchange", handleResize)
        return ()=>{
            window.removeEventListener('resize',handleResize)
            document.addEventListener("orientationchange", handleResize)
            // window.onorientationchange = oldHanddler;
            document.removeEventListener("orientationchange", handleResize)

            handleResize()
        }
    }, [])

    return orientation;
}

export default useOrientation;