import { useState, useEffect } from 'react'

import BackgroundLandscape from '../app/landscape/bg.png'
import BackgroundPortrait from '../app/portrait/bg.png'
import LoadingGif from '../app/loading.gif'

import BetButton from '../betbar/btn-bet.webp'
import RectangleButton from '../betbar/rect.webp'
import DropdownButton from '../betbar/dropdown/dropdown.png'
import bar_form_landscape from '../betbar/bar-form-landscape.webp'
import bar_form_portrait from '../betbar/bar-form-portrait.png'



import PopOutBar from '../history/out/bg.png'
import PopOutBMP from '../history/out/bmp.png'
import PopOutClose from '../history/out/close.png'
import PopOutIconBet from '../history/out/icon_bet.png'
import PopOutIconCopy from '../history/out/icon_copy.png'
import PopOutIconLink from '../history/out/icon_link.png'
import PopOutPlay from '../history/out/play.png'

import TableBar from '../history/table/bg.webp'
import TableHead from '../history/table/bg-head.webp'
import TableX from '../history/table/x.webp'

import SettingBar from '../setting/bg_setting.png'
import VolumeBar from '../setting/bg-range.png'
import IconFullscreen from '../setting/icon_fullscreen.png'
import IconNotFullscreen from '../setting/icon_notfullscreen.png'
import IconTutorial from '../setting/icon_tutorial.png'
import IconVolume from '../setting/icon_volume.png'

import FooterBalance from '../user/footer/balance.png'
import FooterWinLandscape from '../user/footer/bg-win-landscape.png'
import FooterWinPortrait from '../user/footer/bg-win-portrait.png'
import FooterIconUser from '../user/footer/user.png'
// import Dimaond1 from '../game/animation/Symbol 1'
// import Dimaond2 from '../game/animation/Symbol 2'
// import Dimaond3 from '../game/animation/Symbol 3'
// import Dimaond4 from '../game/animation/Symbol 4'
// import Dimaond5 from '../game/animation/Symbol 5'
// import Bomb from '../game/animation/Symbol Bomb'
import TotalPrize from '../game/total-win-box.webp'
import ChestOpen from '../game/chest-open.webp'
import ChestClose from '../game/chest-close.webp'


export const ASSETS_IMAGE_CACHED = "asset-image-cached";

const usePreloadImage = () => {
    const [isReady, setIsReady] = useState(false)
    const [progess, setProgress] = useState(0)

    useEffect(() => {
        setProgress(0)

        const imgs = [
            // App
            BackgroundLandscape,
            BackgroundPortrait,
            LoadingGif,

            // BetBar
            BetButton, RectangleButton, DropdownButton, bar_form_landscape, bar_form_portrait,

            // Game
            // ...Dimaond1,
            // ...Dimaond2,
            // ...Dimaond3,
            // ...Dimaond4,
            // ...Dimaond5,
            // ...Bomb,
            TotalPrize,
            ChestOpen,
            ChestClose,

            // GameLandscape, GamePortrait, FrameLandscape, FramePortrait, PerentageBar,

            // History
            PopOutBar, PopOutBMP, PopOutClose, PopOutIconBet, PopOutIconCopy, PopOutIconLink, PopOutPlay,
            TableBar, TableHead, TableX,

            // Setting
            SettingBar, VolumeBar, IconFullscreen, IconNotFullscreen, IconTutorial, IconVolume,

            // Footer
            FooterBalance, FooterWinLandscape, FooterWinPortrait, FooterIconUser

        ]
        const onePart = 50 / imgs.length

        cacheImages(imgs, onePart)
    }, [])

    const cacheImages = async (srcArray: string[], onePart: number) => {
        const promises = await srcArray.map(src => {
            return new Promise(async function (resolve, reject) {
                const img = new Image();
                const link = document.createElement('link');
                link.rel = 'preload';
                link.href = src;
                link.as = 'image';
                document.head.appendChild(link);
                img.src = src;

                img.onload = () => {
                    setProgress(prev => prev += onePart)
                    resolve(null)
                }
                img.onerror = () => {
                    setProgress(prev => prev += onePart)
                    reject()
                }

                try {

                    if ('caches' in window) {
                        await caches.open(ASSETS_IMAGE_CACHED).then(async (cache) => {
                            if(src.indexOf("data:image")>-1){

                            } else cache.put(src, (await urlContentToDataUri(src) as any));
                        });
                    }
                } catch (error) {

                }

            })
        })


        await Promise.all(promises)

        setIsReady(true)
    }
    return { isReady, progess }

}

export default usePreloadImage

function urlContentToDataUri(url: string) {
    return fetch(url);
}