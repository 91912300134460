import React, { useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import HistoryIcon from "./newicon/ic-history.webp";
import Fullscreen from "./newicon/fullscreen-icon.webp";
import NotFullscreen from "./newicon/fullscreen-icon.webp";
import VolumeIcon from "./newicon/sound-icon.webp";
import Info from "./newicon/info-icon.webp";
import TutorialIcon from "./newicon/tutorial-icon.webp";
import BGSetting from "./setting-bar.webp";
import BGSettingMob from "./setting-bar-mob.webp";
import IconUser from "./ic-user.webp";
import IconWallet from "./ic-wallet.webp";
import BGRange from "./bg-range.png";
import "./index.scss";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { getPlayer, setEffect, setMusic, setVolumeEffect, setVolumeMusic } from "../user/auth/reducer";
import sound from "../../sound";
import HeaderInfo from "../info";
import { closeViewAll, openViewAll } from "../history/reducer";
import useOrientation from "../hook/Orientation";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import { formatCurrency } from "../../tool/money";
import { Player } from "../user/auth/type";

const Setting = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [showVolBar, setShowVolBar] = useState(false);
  const [showInfoBar, setShowInfoBar] = useState(true);
  const music = useAppSelector(
    (state) => state.auth.game?.sounds.music
  ) as boolean;
  const effect = useAppSelector(
    (state) => state.auth.game?.sounds.effect
  ) as boolean;
  const volumeMusic = useAppSelector(
    (state) => state.auth.game?.volume.music
  ) as number;
  const volumeEffect = useAppSelector(
    (state) => state.auth.game?.volume.effect
  ) as number;

  const state = useAppSelector(
    (state) => state
  );

  const player = useAppSelector(getPlayer) as Player;

  const volumeMusicRef = useRef<HTMLInputElement>(null);
  const volumeSfxRef = useRef<HTMLInputElement>(null);
  const handleChangeMusic = () => {
    dispatch(setMusic(!music));
  };


  useEffect(() => {
    sound.enableMusic(music);
    if (volumeMusicRef.current) {
      volumeMusicRef.current.style.backgroundSize =
        ((volumeMusic - MIN) * 100) / (MAX - MIN) + "% 100%";
    }

    sound.updateVolumeMusic(volumeMusic);
  }, [music, volumeMusic]);

  useEffect(() => {

    sound.enableSfx(effect);

    if (volumeSfxRef.current) {
      volumeSfxRef.current.style.backgroundSize =
        ((volumeEffect - MIN) * 100) / (MAX - MIN) + "% 100%";
    }

    sound.updateVolumeSfx(volumeEffect);
  }, [effect, volumeEffect]);


  const orientation = useOrientation();
  const [isFullScreen, setFullscreen] = useState(false);

  const { player_balance, player_name, player_currency } = player;

  const language = i18n.language;

  const handleFormatCurrency = (amount: number): string =>
    formatCurrency(amount, player_currency, language);

  const displayPlayerBalance = useMemo(
    () => formatCurrency(player_balance, player_currency, language),
    [player_balance, player_currency, language]
  );


  const lengthInfo = player_name.length + displayPlayerBalance.length;
  const current_round = useAppSelector(
    (state) => state.history.current_round
  ) as number;


  const setting = useMemo(
    () => orientation === "landscape" ? ([
      {
        icon: () => <div className="info-period">
          <span className="info-period-text">P: {current_round}</span>
        </div>,
        action: () => {

        }
      },
      {
        icon: () => (
          <img onContextMenu={(e) => e.preventDefault()} draggable="false" title={t("setting.history")!} alt="history" src={HistoryIcon} />
        ),
        action: () => {
          dispatch(openViewAll())
          setTimeout(() => {
            dispatch(closeViewAll())
          }, 500);
        },
      },
      // {
      //   icon: (
      //     <img
      //       title={t("setting.info")!}
      //       alt="info"
      //       src={Info}
      //     />
      //   ),
      //   action: () => {

      //   }
      // },
      {
        icon: () => (
          <img
            title={t("setting.sound")!}
            alt="sound"
            src={VolumeIcon}
          />
        ),
        action: () => {
          setShowVolBar((old) => !old)

        }
      },
      {
        icon: () => (
          <img
            title={t("setting.tutorial")!}
            alt="tutorial"
            src={TutorialIcon}
          />
        ),
        action: () => {
          window.open('https://idnelectroniclobby.com/tutorial/gemshunt')

        }
      },
      {
        icon: !isFullScreen ? () => (
          <img onContextMenu={(e) => e.preventDefault()} draggable="false" title={t("setting.fullscreen")!} alt="fullscreen" src={Fullscreen} />
        ) : () => (
          <img onContextMenu={(e) => e.preventDefault()} draggable="false" title={t("setting.exit_fullscreen")!} alt="exit_fullscreen" src={NotFullscreen} />
        ),
        action: () => {
          if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setFullscreen(true);
          } else if (document.exitFullscreen) {
            document.exitFullscreen();
            setFullscreen(false);
          }
        },
      },
    ]) : ([{
      icon: () => (
        <div className={`header-info${lengthInfo > 30 ? " header-info-sm" : ""}`}>
          <div className="header-info-content">
            <img onContextMenu={(e) => e.preventDefault()} draggable="false" className="header-info-icon" alt="" src={IconUser} />

            <span className="header-info-user-value">{player_name}</span>
          </div>

          <div className="header-info-content">
            <img onContextMenu={(e) => e.preventDefault()} draggable="false" className="header-info-icon" alt="" src={IconWallet} />

            <span className="header-info-balance-value">
              {displayPlayerBalance}
            </span>
          </div>
        </div>
      ),
      action: () => { }
    },
    {
      icon: () => (
        <img onContextMenu={(e) => e.preventDefault()} draggable="false" title={t("setting.history")!} alt="history" src={HistoryIcon} />
      ),
      action: () => {
        dispatch(openViewAll())
        setTimeout(() => {
          dispatch(closeViewAll())
        }, 500);
      },
    },
    {
      icon: () => (
        <img
          title={t("setting.sound")!}
          alt="sound"
          src={VolumeIcon}
        />
      ),
      action: () => {
        setShowVolBar((old) => !old)

      }
    },
    {
      icon: () => (
        <img
          title={t("setting.tutorial")!}
          alt="tutorial"
          src={TutorialIcon}
        />
      ),
      action: () => {
        window.open('https://idnelectroniclobby.com/tutorial/gemshunt')
      }
    },
    {
      icon: !isFullScreen ? () => (
        <img onContextMenu={(e) => e.preventDefault()} draggable="false" title={t("setting.fullscreen")!} alt="fullscreen" src={Fullscreen} />
      ) : () => (
        <img onContextMenu={(e) => e.preventDefault()} draggable="false" title={t("setting.exit_fullscreen")!} alt="exit_fullscreen" src={NotFullscreen} />
      ),
      action: () => {
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen();
          setFullscreen(true);
        } else if (document.exitFullscreen) {
          document.exitFullscreen();
          setFullscreen(false);
        }
      },
    },
    ]),
    [isFullScreen, orientation, state.auth.player?.player_balance, state.history.current_round]
  );
  return (
    <div className="setting">
      <img onContextMenu={(e) => e.preventDefault()} draggable="false" className="setting-bg" alt="" src={orientation == "landscape" ? BGSetting : BGSettingMob} />
      <div className="setting-list">
        {setting.map(
          (
            item: {
              icon: () => any;
              action: () => void;
            },
            key
          ) => (
            <div key={key} className="list-item" onClick={() => item.action()}>
              {item.icon()}
            </div>
          )
        )}
      </div>
      <div className="setting-volume">
        <div className="setting-sound-content">
          <div className="setting-sound-item">
            {showVolBar ? (
              <div style={{
                background: 'white', borderRadius
                  : '1rem', padding: '0.5rem 1rem'
              }}>
                <img onContextMenu={(e) => e.preventDefault()} draggable="false" className="setting-bg-range" alt="" src={BGRange} />

                <Grid item >

                  <FormControlLabel

                    control={<Switch onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(setMusic(true))
                      } else {
                        dispatch(setMusic(false))

                      }
                    }} checked={music} color="info" />}
                    label="Musik &nbsp;"
                    labelPlacement="start"
                  />
                  <input
                    type="range"
                    min={MIN}
                    max={MAX}
                    value={volumeMusic}
                    className="volume-range"
                    ref={volumeMusicRef}
                    onChange={(e) =>
                      dispatch(setVolumeMusic(Number(e.target.value)))
                    }
                  />
                </Grid>
                <Grid item>

                  <FormControlLabel
                    // value="start"
                    control={<Switch onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(setEffect(true))
                      } else {
                        dispatch(setEffect(false))

                      }
                    }} checked={effect} color="primary" />}
                    label="Efek &nbsp;&nbsp;"
                    labelPlacement="start"
                  />
                  <input
                    type="range"
                    min={MIN}
                    max={MAX}
                    value={volumeEffect}
                    className="volume-range"
                    ref={volumeSfxRef}
                    onChange={(e) =>
                      dispatch(setVolumeEffect(Number(e.target.value)))
                    }
                  />
                </Grid>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* {true ? <HeaderInfo handleOpen={() => setShowInfoBar((old) => !old)} /> : <></>} */}
    </div>
  );
};

const MIN = 0;
const MAX = 100;

export default Setting;
