
import Bom from './diamond.webp'
import Icon from './Symbol_2.webp'


const Dimaond2 = [
    Icon,
    Bom,

];

export default  Dimaond2