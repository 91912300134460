import React, { Fragment, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import BG from "./bg.webp";
import BGMobile from "./history-box-mobile.webp";
import BGHead from "./bg-head.webp";
import Close from "./x.webp";
import "./index.scss";
import { useAnimateOpening } from "../../betbar/dropdown";
import { AnimatePresence, motion } from "framer-motion";
import useOrientation from "../../hook/Orientation";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { formatCurrency } from "../../../tool/money";
import { Player } from "../../user/auth/type";
import { getPlayer } from "../../user/auth/reducer";
import { History } from "./../type";
import { Result } from "../../result/type";
import Game from "../../game";
import HistoryIcon from "./../../setting/newicon/ic-history.webp";
import Up from "./../../betbar/up.webp";

const Table = ({
  handleOpen,
  active,
  containerRef,
  motionVariant,
}: ReturnType<typeof useAnimateOpening>) => {
  const { t, i18n } = useTranslation();
  const orientation = useOrientation();
  const player = useAppSelector(getPlayer) as Player;
  const history = useAppSelector((state) => state.history.data);
  const { player_balance, player_name, player_currency } = player;
  const dispatch = useAppDispatch();
  const animate = useAnimateOpening("flex");

  const handleFormatCurrency = (amount: number): string =>
    formatCurrency(amount, player_currency, language);
  const language = i18n.language;

  useEffect(() => {
    const scroll = document.getElementById(
      "scroll-range-y"
    ) as HTMLInputElement;

    const panel = document.querySelector(".table-body") as HTMLDivElement;
    const handleInputY = function () {
      const total = panel!.scrollHeight - panel!.offsetHeight;
      scroll.min = "0";
      scroll.max = total.toString();
      panel!.scrollTop = Number(scroll!.value);
    };

    const handleScrollY = function () {
      const total = panel!.scrollHeight - panel!.offsetHeight;
      scroll.min = "0";
      scroll.max = total.toString();
      scroll!.value = panel!.scrollTop.toString();
    };

    // scroll.addEventListener("input", handleInputY);
    // panel.addEventListener("scroll", handleScrollY);
    // return () => {
    //   scroll.removeEventListener("input", handleInputY);
    //   panel.removeEventListener("scroll", handleScrollY);
    // };
  }, []);

  useEffect(() => {
    const scroll = document.getElementById(
      "scroll-range-x"
    ) as HTMLInputElement;

    const panel = document.querySelector(".table-body") as HTMLDivElement;
    const panel2 = document.querySelector(
      ".table-head-scroll"
    ) as HTMLDivElement;
    const handleInputX = function () {
      const total = panel!.scrollWidth - panel!.offsetWidth;
      scroll.min = "0";
      scroll.max = total.toString();
      panel!.scrollLeft = Number(scroll!.value);
      panel2!.scrollLeft = Number(scroll!.value);
    };

    const handleScrollX = function () {
      const total = panel!.scrollWidth - panel!.offsetWidth;
      scroll.min = "0";
      scroll.max = total.toString();
      scroll!.value = panel!.scrollLeft.toString();
      panel2!.scrollLeft = panel!.scrollLeft;
    };
    // scroll.addEventListener("input", handleInputX);
    // panel.addEventListener("scroll", handleScrollX);
    // return () => {
    //   scroll.removeEventListener("input", handleInputX);
    //   panel.removeEventListener("scroll", handleScrollX);
    // };
  }, []);
  const { viewAll } = useAppSelector((state) => state.history);

  useEffect(() => {
    if (viewAll) {
      handleOpen();
    }
  }, [viewAll]);

  return (
    <motion.div
      initial="close"
      animate={active ? "open" : "close"}
      variants={motionVariant}
      // @ts-ignore
      ref={containerRef}
      className="table-container"
    >
      {" "}
      <div className="black" onClick={() => handleOpen()}></div>
      <img onContextMenu={(e)=>e.preventDefault()} draggable="false" className="table-bg" alt="" src={orientation === "landscape" ? BG : BGMobile} />
      <div className="table-title">
        <div className="title">
          {/* <img onContextMenu={(e)=>e.preventDefault()} draggable="false" className="title-bet-icon" alt="" src={HistoryIcon} />
          {t("history.table.title")} */}
          </div>
        <img
          title={t("history.table.close")!}
          className="close"
          alt=""
          src={Close}
          onClick={() => handleOpen()}
        />
      </div>
      <div className="table">
        <div
          className="table-head"
          style={{ backgroundColor: `#83561e`, borderRadius: '1rem' }}
        >
          {/* <img onContextMenu={(e)=>e.preventDefault()} draggable="false" className="head-bg" alt="" src={BGHead} /> */}
          <div className="table-head-scroll">
            <div className="head-list">
              <div className="list-item td-date">{t("history.table.date")}</div>
              <div className="list-item td-mines">{t("history.table.mines")}</div>
              <div className="list-item td-period">
                {t("history.table.period")}
              </div>
              <div className="list-item td-totalbet">
                {t("history.table.totalbet")}
              </div>
              <div className="list-item td-payout">
                {t("history.table.payout")}
              </div>
              <div className="list-item td-icon">
                {t("history.table.detail")}
              </div>
            </div>
          </div>
        </div>
        <div className="table-body">
          <table>
            <tbody>
            <AnimatePresence mode={"popLayout"}>

              {history.map((items, idx) => {
                const grouped:
                  any
                  // {[i: typeof items.result[number]["prize"]]: typeof items.result} 
                  = {};
                return (
                  <Fragment
                    key={"history-table".concat(
                      items.round_id
                        .toString()
                        .concat("first")
                        .concat(idx.toString())
                    )}

                  >
                    <tr
                    >
                      <td align="center" className={"td-date"}>
                        {items.created_date}
                      </td>
                      <td align="center" className={"td-mines"}>
                        {items.data.detail_bet.mines}
                      </td>
                      <td align="center" className={"td-period"}>
                        {items.round_id}
                      </td>
                      <td align="center" className={"td-totalbet"}>
                        {handleFormatCurrency(items.data.total_amount)}
                      </td>
                      <td align="center" className={"td-payout"}>
                        {handleFormatCurrency(items.data.total_win)}
                      </td>
                      <td
                        align="center"
                        className={"td-icon"}
                        onClick={() => {
                          animate.handleClose(() => { });
                          animate.open(animate.active === idx ? -1 : idx);
                        }}
                      >
                        {animate.active === idx ? <img onContextMenu={(e)=>e.preventDefault()} draggable="false" className="" alt="" src={Up} style={{  }} /> : <img onContextMenu={(e)=>e.preventDefault()} draggable="false" className="" alt="" src={Up} style={{ transform: 'rotate(90deg)' }} />}




                      </td>
                    </tr>
                    <tr                                      
                    style={{ borderTop: animate.active === idx ? '0' : '2px solid rgb(230, 217, 195)', borderBottom: animate.active === idx ? '2px solid rgb(230, 217, 195)' : '0'  }}>
                      <td colSpan={6} align={"center"}>
                        <motion.div
                          initial="close"
                          animate={animate.active === idx ? "open" : "close"}
                          variants={animate.motionVariant}
                          // @ts-ignore
                          ref={animate.containerRef}
                          className="retable"
                          key={"history-table-item".concat(
                            idx.toString().concat(idx.toString())
                          )}
                        >
                          {/* History game here */}
                          <Game idx={idx} dataProps={{ payload: items.result }} />
                        </motion.div>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
              </AnimatePresence>
            </tbody>
          </table>
        </div>
        {/* <div className="slider-y">
          <form className="rangeSlider">
            <input
              className="scroll-range"
              id="scroll-range-y"
              type="range"
              defaultValue={"0"}
            />
          </form>
        </div>
        <div className="slider-x">
          <form className="rangeSlider">
            <input
              className="scroll-range"
              id="scroll-range-x"
              type="range"
              defaultValue={"0"}
            />
          </form>
        </div> */}
      </div>
    </motion.div>
  );
};

export default Table;
