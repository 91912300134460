import React, { useState } from 'react'

import Logo from './Arcade.png'
// import LoadingImage from './loading.gif'

interface IProps {
    progess: number
}

const Loading = ({ progess }: IProps) => {
    return (
        <div className='loading-screen'>
            <img onContextMenu={(e)=>e.preventDefault()} draggable="false" src={Logo} alt='' />
            {/* <img onContextMenu={(e)=>e.preventDefault()} draggable="false" src={LoadingImage} alt='' /> */}

            <div id="myProgress">
                <div id="myBar" style={{ width: `${progess}%` }}></div>
            </div>
        </div>
    )
}

export default Loading