import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ResultResponse, ResultState, SendBet } from "./type";
import { updateBalance } from "../user/auth/reducer";
import { client } from "../../tool/client";
import { Endpoints } from "../../const/Api";
import { changeRound, newRound, refreshHistory } from "../history/reducer";
import { restart, restartAuto } from "../game/reducer";
import { changeNumOfBet, startBet, updateTotalProfit } from "../betbar/reducer";

const initialState: ResultState = {
  result: [],
  total_win: 0,
  payload: {},
  bet_win: null,
  singleCount: -1,
  inQueue: 0,
  status: "idle",
  error: null,
  auto: false,
  loadLastBet: false,
};

let nextPeriode: any;
let domain: any;
let reset5S: any;

export const sendBetAction = createAsyncThunk(
  "result/sendbet",
  async (params: SendBet, { dispatch, getState }) => {
    try {
      clearTimeout(reset5S);
      const state: any = getState();
      if (params.button_bet.state == "auto") {
        if (!state.playerbet[state.playerbet.currMode].start)
          throw Error("Stoped");
        if (state.playerbet.auto.numberOfBet < 1) {
          dispatch(changeNumOfBet(Infinity));
          // @ts-ignore
          document.querySelector("#numberOfBet")!.value = Infinity.toString();
        }
        if (
          (params.button_bet.path! as string).split(",").length >
          25 - params.button_bet.mines
        )
          throw Error(
            "Pilihan melebihi batas. Mohon kurangin pilihan anda, dan silahkan mencoba lagi."
          );
        const s: any = getState();
        dispatch(changeNumOfBet(s.playerbet.auto.numberOfBet - 1));
        dispatch(newRound());
      }

      // state.status
      // const response = await client.get(Endpoints.SendBet, params)
      domain = domain || (await (await fetch(Endpoints.Domain)).json());
      // console.log(params.round_id);
      if (domain.useGenerate) {
        // const response = await client.get(`${domain.generate}?mines=${params.button_bet.mines}&amount=${params.button_bet.amount}`);
        dispatch(updateBalance(-params.total_amount));

        const data = {
          initial: {
            round_id: 5,
            mines: 1,
            path: null,
            prize: 0,
          },
          sweep: {
            round_id: params.button_bet.round_id,
            // mines:null,
            path: [params.button_bet.path],
            mines: [params.button_bet.path],
            prize: 0.98,
          },
          cashout: {
            round_id: 5,
            mines: ["25"],
            path: ["1"],
            prize: 1.041,
          },
          auto: {
            round_id: 5,
            mines: ["3", "1"],
            path: ["1", "25"],
            prize: 1.041,
          },
        };

        const response: any = {};
        response.data = {};
        response.data.data = data[params.button_bet.state];
        await new Promise((r)=>r(true));
        
        if (params.button_bet.state == "initial") {
          dispatch(updateBalance(-Number(params.total_amount)));
          dispatch(restart());
          dispatch(resetResult());
          dispatch(updateTotalProfit(0));
          dispatch(startBet());

        }
        if (params.button_bet.state == "auto" && !response.data.data.prize) {
          dispatch(updateBalance(-Number(params.total_amount)));
        }
        if (params.button_bet.state == "auto" && response.data.data.prize) {
          dispatch(
            updateBalance(
              +(
                Number(params.total_amount) * Number(response.data.data.prize) -
                Number(params.total_amount)
              )
            )
          );
        }

        if (params.button_bet.state == "cashout") {
          dispatch(
            updateBalance(
              +(Number(params.total_amount) * Number(response.data.data.prize))
            )
          );
        }
        // }

        dispatch(
          updateTotalProfit(
            Number(params.total_amount) * Number(response.data.data.prize)
          )
        );

        if (
          params.button_bet.state === "auto" ||
          params.button_bet.state === "cashout" ||
          (params.button_bet.state === "sweep" && response.data.data.mines)
        ) {
          if (
            params.button_bet.state === "cashout" ||
            (params.button_bet.state === "sweep" && response.data.data.mines)
          )
            dispatch(newRound());
          setTimeout(() => {
            dispatch(refreshHistory());
          }, 2000);
        }

        const state: any = getState();
        if (state.playerbet.currMode == "auto")
          setTimeout(() => {
            dispatch(resultFinished());
            dispatch(restartAuto());
            nextPeriode = setTimeout(() => {
              const state: any = getState();
              if (
                state.playerbet.currMode == "auto" &&
                state.playerbet.auto.numberOfBet &&
                state.playerbet.auto.start
              ) {
                dispatch(
                  sendBetAction({
                    game: "GH000001",
                    total_amount:
                      state.playerbet[state.playerbet.currMode].amount ||
                      state.auth.game.limit_bet.minimal,
                    button_bet: {
                      amount:
                        state.playerbet[state.playerbet.currMode].amount ||
                        state.auth.game.limit_bet.minimal,
                      mines: state.playerbet[state.playerbet.currMode].mines,
                      path: state.game.selected.join(","),
                      round_id: Number(state.history.current_round),
                      state: "auto",
                    },
                    round_id: Number(state.history.current_round),
                  })
                );
              } else {
                clearTimeout(nextPeriode);
              }
            }, 1000);
            // }, 100);
          }, 3000);

        return { ...response.data.data, last_bet: params };
      } else {
        // dispatch(updateTotalProfit(0));
        const response = await client.pept(`${domain.sendbet}`, params);
        if (params.button_bet.state == "initial") {
          if (!response.data.status) {
            throw new Error(response.data.message);
          } else {
            dispatch(restart());
            dispatch(resetResult());
            dispatch(updateTotalProfit(0));
            dispatch(startBet());
          }
        }
        // if(response.data.status == true){
        if (params.button_bet.state == "initial") {
          dispatch(updateBalance(-Number(params.total_amount)));
        }
        if (params.button_bet.state == "auto" && !response.data.data.prize) {
          dispatch(updateBalance(-Number(params.total_amount)));
        }
        if (params.button_bet.state == "auto" && response.data.data.prize) {
          dispatch(
            updateBalance(
              +(
                Number(params.total_amount) * Number(response.data.data.prize) -
                Number(params.total_amount)
              )
            )
          );
        }

        if (params.button_bet.state == "cashout") {
          dispatch(
            updateBalance(
              +(Number(params.total_amount) * Number(response.data.data.prize))
            )
          );
        }
        // }

        dispatch(
          updateTotalProfit(
            Number(params.total_amount) * Number(response.data.data.prize)
          )
        );

        if (
          params.button_bet.state === "auto" ||
          params.button_bet.state === "cashout" ||
          (params.button_bet.state === "sweep" && response.data.data.mines)
        ) {
          if (
            params.button_bet.state === "cashout" ||
            (params.button_bet.state === "sweep" && response.data.data.mines)
          )
            dispatch(newRound());
          setTimeout(() => {
            dispatch(refreshHistory());
          }, 4500);

          reset5S = setTimeout(() => {
            dispatch(resultFinished());
            setTimeout(() => {
              dispatch(resetResult());
            }, 500);
          }, 4500);
        }

        const state: any = getState();
        if (state.playerbet.currMode == "auto")
          setTimeout(() => {
            dispatch(resultFinished());
            dispatch(restartAuto());
            dispatch(resetResult());
            nextPeriode = setTimeout(() => {
              const state: any = getState();
              if (
                state.playerbet.currMode == "auto" &&
                state.playerbet.auto.numberOfBet &&
                state.playerbet.auto.start
              ) {
                dispatch(
                  sendBetAction({
                    game: "GH000001",
                    total_amount:
                      state.playerbet[state.playerbet.currMode].amount ||
                      state.auth.game.limit_bet.minimal,
                    button_bet: {
                      amount:
                        state.playerbet[state.playerbet.currMode].amount ||
                        state.auth.game.limit_bet.minimal,
                      mines: state.playerbet[state.playerbet.currMode].mines,
                      path: state.game.selected.join(","),
                      round_id: Number(state.history.current_round),
                      state: "auto",
                    },
                    round_id: Number(state.history.current_round),
                  })
                );
              } else { 
                clearTimeout(nextPeriode);
              }
            }, 1000);
            // }, 100);
          }, 3000);

        return { ...response.data.data, last_bet: params };
      }

      // return response.data
    } catch (error: any) {
      if (params.button_bet.state == "auto") {
        dispatch(forcefinishAuto());
        dispatch(changeRound(params.button_bet.round_id));
      }
      throw Error(error?.message);
    }
  }
);

// export const lastPathAction = createAsyncThunk(
//   "result/lastPath",
//   async (params: SendBet, { dispatch, getState }) => {
//     try {
//       const state: any = getState()
//       // if (state.result.status === 'start-spin') throw Error("Can't get result in twice!");
//       // state.status
//       // const response = await client.get(Endpoints.SendBet, params)
//       const domain = await (await fetch(Endpoints.Domain)).json();

//       const response = await client.get(`${domain.lastPath}?game_code=${params.game}&round_id=${params.round_id}`);
//       // dispatch(updateBalance(-params.total_amount));

//       return { ...response.data.data, last_bet: params };

//       // return response.data
//     } catch (error: any) {

//       throw Error(error?.message);
//     }
//   }
// );

// export const sendAutoBetAction = createAsyncThunk(
//   "result/sendautobet",
//   async (params: SendBet, { dispatch, getState }) => {
//     try {
//       const state: any = getState()
//       if(!state.result.auto) throw Error("Auto bet cancelled");
//       // const response = await client.get(Endpoints.SendBet, params)
//       const domain = await (await fetch(Endpoints.Domain)).json();

//       if(domain.useGenerate){

//         const response = await client.get(`${domain.generate}?mines=${params.button_bet.mines}`);
//         dispatch(updateBalance(-params.total_amount));

//         return  {...response.data, last_bet: params};

//       }else {
//         const response = await client.post(`${domain.sendbet}`, params);
//         dispatch(updateBalance(-params.total_amount));

//         return  {...response.data.data, last_bet: params};
//       }
//       // return response.data
//     } catch (error: any) {
//         // dispatch(endAuto())
//       throw Error(error?.message);
//     }
//   }
// );

export const resultSlice = createSlice({
  name: "result",
  initialState,
  reducers: {
    resetResult: (state, action: PayloadAction) => {
      state.status = "idle";
      state.result = [];
      state.bet_win = null;
      state.payload = {};
      state.total_win = 0;
      state.singleCount = -1;
      state.singleCount = -1;
      state.auto = false;
      state.loadLastBet = false;
    },
    updateTotalWin: (state, action: PayloadAction<number>) => {
      state.total_win += action.payload;
    },
    finishAnimation: (state, action: PayloadAction<number | undefined>) => {
      state.status = "end-spin";
      // update total win
      if (state.inQueue > 0) {
        state.inQueue = state.inQueue - 1;
      }
      if (typeof action.payload == "number")
        state.total_win += state.payload[action.payload].total_win;
      if (typeof action.payload == "undefined")
        state.total_win = state.payload.total_win;
    },
    showWin: (state, action: PayloadAction) => {
      state.status = "show-win";
    },
    showError: (state, action: PayloadAction<string>) => {
      state.status = "failed";
      state.error = action.payload;
    },
    changeSelectChest: (state, action: PayloadAction) => {
      state.status = "change-selected-chest";
    },
    resultFinished: (state, action: PayloadAction) => {
      state.status = "finished";
    },
    startAnimation: (state, action: PayloadAction) => {
      state.status = "start-spin";
    },
    endAuto: (state, action: PayloadAction) => {
      state.auto = false;
    },
    startAuto: (state, action: PayloadAction) => {
      state.auto = true;
    },
    forcefinishAuto: (state, action: PayloadAction) => {
      state.status = "force-end-spin";
    },
    loadLastPath: (state, action: PayloadAction<ResultResponse>) => {
      state.status = "succeeded";
      state.loadLastBet = true;
      state.result = action.payload as any;
      state.payload = action.payload as any;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(sendBetAction.pending, (state, action) => {
        state.status = "loading";
        state.result = [];
        state.bet_win = null;
        state.total_win = 0;
      })
      .addCase(
        sendBetAction.fulfilled,
        (state, action: PayloadAction<ResultResponse>) => {
          state.result = action.payload as any;
          state.payload = action.payload as any;
          state.status = "succeeded";
          state.loadLastBet = false;
        }
      )
      .addCase(sendBetAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    // .addCase(
    //   lastPathAction.fulfilled,
    //   (state, action: PayloadAction<ResultResponse>) => {
    //     state.status = "succeeded";
    //     state.result = action.payload.game_result.result as any;
    //     // if(state.singleCount==9){
    //     // //   state.singleCount = -1
    //     // // }
    //     // state.singleCount = state.singleCount+1
    //     // state.inQueue = state.inQueue+1
    //     // state.payload[state.singleCount] = action.payload as any;

    //   }
    // )
    // .addCase(lastPathAction.rejected, (state, action) => {
    //   state.status = "failed";
    //   state.error = action.error.message;
    // });
  },
});

export const {
  resetResult,
  finishAnimation,
  showWin,
  resultFinished,
  startAnimation,
  endAuto,
  startAuto,
  updateTotalWin,
  forcefinishAuto,
  loadLastPath,
  changeSelectChest,
  showError,
} = resultSlice.actions;

export default resultSlice.reducer;
