
import { Endpoints } from "../const/Api"
import blk from "./blank"
import _0x59abd2 from "./Decryption"

interface Params extends Omit<RequestInit, 'body'> {
    body?: Record<string, any> | string

}

export async function client(endpoint: string, { body, ...customConfig }: Params = {}) {
    const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
    blk;
    const config: RequestInit = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    }

    if (body) {
        config.body = typeof body == "string" ? body : JSON.stringify(body)
    }



    let data
    try {

        const response = await window.fetch(endpoint, config)
        data = await response.json()

        if (response.ok) {
            // Return a result object similar to Axios
            return {
                status: response.status,
                data,
                headers: response.headers,
                url: response.url,
            }
        }

        throw new Error(data ? data?.message : response.statusText)
    }
    catch (err: any) {
        return Promise.reject(err)
    }
}

client.get = function (endpoint: string, customConfig = {}) {
    return client(endpoint, { ...customConfig, method: 'GET' })
}

client.post = function (endpoint: string, body: Record<string, any>, customConfig = {}) {
    return client(endpoint, { ...customConfig, body })
}

client.pept = async function (endpoint: string, body: Record<string, any>, customConfig = {}) {
    const pept: any = (new _0x59abd2());
    return client(endpoint, {
        ...customConfig, body:
            JSON.stringify({
                data: await pept["bce13cb9b55baa2409fa33259ad589319"](JSON.stringify(body))
            })
    })
}




