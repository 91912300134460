import React from 'react'

interface Props {
    message?: string | null
}

const Loading = ({ message }: Props) => {
    return (
        <div className='error-screen'>
            { message ? message : 'Error' }
        </div>
    )
}

export default Loading