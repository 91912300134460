import React from 'react'
import Background from './bg.png'

const Portrait = () => {
    return (
        <><div className="bg-wrapper">
            <img onContextMenu={(e)=>e.preventDefault()} draggable="false" alt='' src={Background} className='background-image' />
        </div>
        </>
    )
}

export default Portrait