import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import BGViewAll from './view-all.png'
import "./index.scss";
import Table from "./table";
import { useAnimateOpening } from "../betbar/dropdown";
import Out from "./out";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { History as HistoryType } from "./type";
import { getColor } from "../color";
import { closeViewAll } from "./reducer";

const History = () => {
  const { t, i18n } = useTranslation();

  const animate = useAnimateOpening('flex')
  const { handleOpen } = animate;
  const { last_history: historyData, viewAll } = useAppSelector((state) => state.history)

  useEffect(() => {
    (async () => {
    })();
    // handleOpen()
  }, [])



  return (
    <div className="history">
   
      <Table {...{ ...animate }} />
      {/* {outIdx > -1 ? <Out {...{ ...animateOut, dataHistory: historyData[outIdx], setOutIdx }} /> : <></>} */}

    </div>
  );
};

export default History;
