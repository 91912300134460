import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import BG from "./error-box.webp";
import Icon from "./error-icon.webp";
import Close from "./x.webp";

import "./index.scss";
import { useAnimateOpening } from "../betbar/dropdown";
import { motion } from "framer-motion";
import { getColor } from "../color";
import { formatCurrency } from "../../tool/money";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { authLogin, getPlayer, resetAuth } from "../user/auth/reducer";
import { Player } from "../user/auth/type";
import { resetResult } from "./reducer";
import { fetchHistory, resetHistory } from "../history/reducer";
import { restart } from "../game/reducer";
interface ErrorIProps extends ReturnType<typeof useAnimateOpening> {
}

const noException = [
    'already processed',
    'there is an error with game period',
    'There are still transactions in process',
];

const Error = ({
    handleOpen,
    active,
    handleClose: close,
    setActive,
    containerRef,
    motionVariant
}: ErrorIProps) => {
    const { t, i18n } = useTranslation();
    const player = useAppSelector(getPlayer) as Player;
    const result = useAppSelector((state)=>state.result);
    const dispatch = useAppDispatch();

    const language = i18n.language;
    const handleClose = (message: string) => {
        close(()=>{
            dispatch(resetResult());

            if(message!.includes('Missmatch')){
                dispatch(restart());
                dispatch(resetAuth());
                dispatch(authLogin());
                dispatch(resetHistory());
                dispatch(fetchHistory());
            }
        });
    }
    const { player_balance, player_name, player_currency } = player;

    const handleFormatCurrency = (amount: number): string =>
        formatCurrency(amount, player_currency, language);
    const [color, setColor] = useState();

    useEffect(() => {
        if(result.status=="failed" && result.error && noException.every((except)=>!result.error!.toLowerCase().includes(except.toLowerCase())))
        !active && handleOpen();
    }, [active, result.status, result.error])

    return (
        <motion.div
            initial="close"
            animate={active ? "open" : "close"}
            variants={motionVariant}
            // @ts-ignore
            ref={containerRef}
            className="result-error"
        >
            {/* <div className="black"
                onClick={() => handleClose()}
            ></div> */}
            
            <img onContextMenu={(e)=>e.preventDefault()} draggable="false" className="result-error-bg" alt="" src={BG} />
            <div className="result-error-title">
        <div className="title-bet"></div>
          
        <img onContextMenu={(e)=>e.preventDefault()} draggable="false" className="title-close" alt="" src={Close} onClick={()=>handleClose(result.error!)} />
      </div>
            <div className="multiplier">
        {/* <div className="multiplier-bg" style={{color: `red`}} /> */}
            <img onContextMenu={(e)=>e.preventDefault()} draggable="false" className="result-error-bg" alt="" src={Icon} />
        <div className="multiplier-label">{"ERROR"}</div>
        <div className="multiplier-label-message">{result.error}</div>
      </div>
        </motion.div>
    );
};

export default Error;
