import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import usePreloadImage from "./../hook/PreloadImage";
import Orientation from "./Orientation";
import Landscape from "./landscape";
import Footer from "./../user/footer";
import Loading from "./Loading";
import ErrorPage from "./ErrorPage";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import "./index.scss";
import History from "../history";
import Setting from "../setting";
import BetBar from "../betbar";
import Game from "../game";
import sound from "../../sound";
import Portrait from "./portrait";
import { changeList } from "../betbar/reducer";
import { useOnKeyDown } from "../hook/KeyDown";


import { StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stat from "../stat";
import Error from "../result/error";
import { useAnimateOpening } from "../betbar/dropdown";
const confirmationMessage = 'Apakah anda yakin ingin me-refresh page?'
+ 'Karena akan mempengaruhi proses animasi game';


const App = () => {
  let content: any;
  const [open, setOpen] = React.useState(false);
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [portalOK, setPortalOK] = useState(false);
  const authStatus = useAppSelector((state) => state.auth.status);
  const authError = useAppSelector((state) => state.auth.error);
  // const active_menu = useAppSelector(state => state.auth.game?.active_menu)
  const music = useAppSelector(state => state.auth.game?.sounds.music) as boolean
  // const effect = useAppSelector(state => state.auth.game?.sounds.effect) as boolean

  const historyStatus = useAppSelector((state) => state.history.status);
  const historyError = useAppSelector((state) => state.history.error);

  // const sendbetStatus = useAppSelector(state => state.result.status)
  // const sendbetError = useAppSelector(state => state.result.error)
  // const totalwinResult = useAppSelector(state => state.result.total_win) as number

  const player_language = useAppSelector(
    (state) => state.auth.player?.player_language
  );


  const animateError = useAnimateOpening('flex')

  // const popup_message = useAppSelector(state => state.auth.game?.popup_message)

  const { isReady, progess } = usePreloadImage();
  const [progessApi, setProgessApi] = useState(0)

  const [allowPlayAudio, setAllowPlayAudio] = useState<boolean>(false)

  const [currentOrientation, setCurrentOrientation] = useState<Orientation>(
    Orientation.Landscape
  );

  useEffect(() => {
    if (authStatus === 'succeeded') {
      setProgessApi(prev => prev + 10)
    }
  }, [authStatus])

  useEffect(() => {
    if (historyStatus === 'succeeded') {
      setProgessApi(prev => prev + 10)
    }
  }, [historyStatus])

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== "undefined" && window.visualViewport) {
        let widthToHeight: number;
        let standartScreenWidth: number;
        let standartScreenHeight: number;

        let width: number = window.visualViewport.width;
        let height: number = window.visualViewport.height;

        let orientation: Orientation =
          width >= height ? Orientation.Landscape : Orientation.Portrait;

        if (orientation === Orientation.Landscape) {
          widthToHeight = 16 / 9;
          standartScreenWidth = 1920; // 2208 // 1104
          standartScreenHeight = 1080; // 1242 // 621
        } else {
          widthToHeight = 1125 / 2436;
          standartScreenWidth = 1125; // 2208 // 1104
          standartScreenHeight = 2436; // 1242 // 621
        }

        const newWidthToHeight = width / height;

        if (newWidthToHeight > widthToHeight) {
          // window width is too wide relative to desired game width
          width = height * widthToHeight;
        } else {
          // window height is too high relative to desired game height
          height = width / widthToHeight;
        }

        const scale = width / standartScreenWidth;

        setCurrentOrientation(orientation);

        const root = document.documentElement;
        root?.style.setProperty("--scale-game", `scale(${scale})`);

        root?.style.setProperty("--width-game", `${standartScreenWidth}px`);

        root?.style.setProperty("--height-game", `${standartScreenHeight}px`);
        // root?.style.setProperty("--scale-game", `scale(${scale})`);
        // root?.style.setProperty("--width-game", `${100}vw`);

        // root?.style.setProperty("--height-game", `${100}vh`);

        root?.style.setProperty(
          "--width-screen",
          `${window.visualViewport.width}px`
        );

        root?.style.setProperty(
          "--height-screen",
          `${window.visualViewport.height}px`
        );
      }
    };

    // (async () => {
    //   dispatch(changeList(await (await fetch('/list.json')).json()))
    // })()

    const handleMousedown = () => {
      setAllowPlayAudio(true)
    };
    
    const handleReload = (e: any) => {
      // @ts-ignore
      if(!window.__EVENT_RELOAD){
        e.preventDefault();
      }
    //   console.log(e)
    //   // @ts-ignore
    //   if(!e.mySecretVariableName) {
    //     // @ts-ignore
    //     e.originalEvent =  new e.constructor(e.type, e);
    //     // @ts-ignore
    //     window.__EVENT_RELOAD = e
    //     handleClickOpen();
    //     (e || window.event).returnValue = confirmationMessage; 
    //   return confirmationMessage; 
    // } else {
    //   // window.location.reload()
    //   // return;
    // }
    //   // return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    window.addEventListener("fullscreenchange", handleResize);

    document.addEventListener("click", handleMousedown, { once: true });
    // window.addEventListener('beforeunload', handleReload)
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
      window.removeEventListener("fullscreenchange", handleResize);

      document.removeEventListener("click", handleMousedown);
      // window.removeEventListener('beforeunload', handleReload)

    };
  }, []);

  useOnKeyDown((e) => {
      // @ts-ignore
    if(!e.mySecretVariableName) {
      // @ts-ignore
      e.originalEvent =  new e.constructor(e.type, e);
      // @ts-ignore
      window.__EVENT_RELOAD = e
      handleClickOpen();
      e.preventDefault();
    } else {
      window.location.reload()
    }
   
    // @ts-ignore
  }, [{ key: 'r', ctrlKey: true }, { metaKey: true, key: 'r' }, 'F5'])

  useEffect(() => {
    // change language
    if (player_language && player_language !== i18n.language) {
      i18n.changeLanguage(player_language);
    }
  }, [player_language]);

  // useEffect(() => {
  // 	if (sendbetStatus === 'failed') {
  // 		dispatch(setPopupMessage((sendbetError || '')))
  // 	}
  // 	else if (sendbetStatus === 'end-spin') {
  // 		// jika ada win push total win ke state game
  // 		if (totalwinResult > 0) {
  // 			dispatch(showWin())
  // 		}
  // 		else {
  // 			dispatch(resultFinished())
  // 		}

  // 		// addedHistory
  // 		dispatch(refreshHistory())

  // 	}
  // 	else if (sendbetStatus === 'finished') {
  // 		dispatch(betSended())

  // 		if (totalwinResult > 0) {
  // 			dispatch(updateBalance(totalwinResult))
  // 		}
  // 	}

  // }, [sendbetStatus])

  useEffect(() => {
    sound.enableMusic(music);

    if (!music) {
      sound.stopBgMusic()
    }

    if (music && allowPlayAudio) {
      sound.playBgMusic()

    }
  }, [allowPlayAudio])


  useEffect(() => {

    let portalGenerated: any;
    const checker = () => {
      try {

        // console.log("phaser ready check", window.game.scene.scenes[0].load.queue.entries.length);
      } catch (error) {

      }

      if (!window.add || window.game.scene.scenes[0].load.queue.entries.length) {
        portalGenerated = setTimeout(checker, 500);
        return;
      }

      if (window.add && !window.game.scene.scenes[0].load.queue.entries.length) {
        setPortalOK(true);
        clearTimeout(portalGenerated);
        return;
      }
    };

    portalGenerated = setTimeout(checker, 500);

  }, []);


  if (( !portalOK && !isReady || authStatus === "loading" || historyStatus === "loading") && !content) {
    return (
      <div className={`app`}>
        <div className={`game-area ${currentOrientation}`}>
          <Loading progess={progess + progessApi} />
        </div>
      </div>
    );
  }

  if (authStatus === "failed") {
    return (
      <div className="app">
        <ErrorPage message={authError} />
      </div>
    );
  }

  if (historyStatus === "failed") {
    return (
      <div className="app">
        <ErrorPage message={historyError} />
      </div>
    );
  }


  if ( portalOK && authStatus === "succeeded" && historyStatus === "succeeded" && currentOrientation && !content) {
    content = (<>

      {currentOrientation === Orientation.Landscape ? <div className={`${currentOrientation}`}>
        {/* <Footer /> */}

      </div> : <></>}
      <div className={`game-area ${currentOrientation}`}>
      {currentOrientation === Orientation.Landscape ? <Landscape /> : <Portrait />}

        <Setting />
        {/* <Stat /> */}
        <Error {...{...animateError}} />

        <Game />
        <History />
        <BetBar />
        {/* {currentOrientation === Orientation.Portrait ? <Footer /> : <></>} */}

        {/* { popup_message && <PopupMessage message={popup_message} /> } */}

        {/* { ( sendbetStatus === 'loading' || sendbetStatus === 'succeeded' ) &&  <Wheel /> }
				{ ( sendbetStatus === 'show-win' ) &&  <YouWon /> } */}

        {/* { active_menu === 'history' && <MenuHistory orientation={currentOrientation} /> }
				{ active_menu === 'sound' && <MenuSound /> }
				{ active_menu === 'language' && <MenuLanguage /> } */}
      </div>
    </>
    );
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // @ts-ignore
    window.__EVENT_RELOAD = null
  };


  return <>
    <div className={`app app-${currentOrientation} ${navigator.userAgent.toLowerCase().indexOf('mac os')>-1?'safari':''} ${navigator.userAgent.toLowerCase().indexOf('iphone')>-1?'iPhone':''}`}>{content}</div>
    <StyledEngineProvider injectFirst>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {confirmationMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* @ts-ignore */}
          <Button onClick={()=>{
            // @ts-ignore
            const event = window.__EVENT_RELOAD
            event.originalEvent.mySecretVariableName = "i handled it";
            // @ts-ignore
            if (document.createEvent) {
              document.body.dispatchEvent(event.originalEvent);
            } else {
            // @ts-ignore
            document.body.fireEvent(event.originalEvent.eventType, event.originalEvent);
            }
          }}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </StyledEngineProvider>
  </>
};

export default App;
