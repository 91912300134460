
import { useEffect, useLayoutEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '../../store/hook'
import { openBomb } from './reducer'
import { countOpen } from './reducer'
import { stopBet } from '../betbar/reducer'
import { sendBetAction } from '../result/reducer'
import { SendBet } from '../result/type'
import sound from '../../sound'

const defaultImageProps = {
    draggable: false,
    onContextMenu: (e: any) => e.preventDefault()
}

const symArr = [
    "sym1", "sym2", "sym3", "sym4", "sym5"
];

const symArrDark = [
    "sym-1", "sym-2", "sym-3", "sym-4", "sym-5"
];


const startX = 100;
const startY = 355;

type ContentStateType = "hide" | "bomb" | "diamond" | "start";
const syms: any[] = [];

// cleanup game spine object 
export const Chest = ({ dataProps, idxChest, handleClick, selected, idxRow }: any) => {

    const dispatch = useAppDispatch();
    const randomSym = symArr[Math.floor(Math.random() * symArr.length)];
    const randomSymDark = symArrDark[Math.floor(Math.random() * symArrDark.length)];


    const [open, setOpen] = useState(false);
    const [contentState, setContentState] = useState<ContentStateType>("hide");
    const [shadowOpen, setShadowOpen] = useState(false);

    const result = useAppSelector((state) => state.result);
    const playerbet = useAppSelector((state) => state.playerbet);
    const game = useAppSelector((state) => state.game);
    const history = useAppSelector((state) => state.history);

    // const [ isSelect, setIsSelect ] = useState(false);

    // const ref: any = useRef(null);





    const handleCashOut = () => {

        const sendBet: SendBet = {
            game: "GH000001",
            total_amount: playerbet[playerbet.currMode].amount,
            button_bet: {
                amount: playerbet[playerbet.currMode].amount,
                mines: playerbet[playerbet.currMode].mines,
                path: result.payload.path.join(","),
                round_id: Number(history.current_round),
                state: "cashout"
            },
            round_id: Number(history.current_round)
        };
        dispatch(sendBetAction(sendBet));
        dispatch(stopBet());
    }


    useLayoutEffect(() => {
        // requestAnimationFrame(() => {

        if (!dataProps && playerbet.currMode == "auto" && window.container[idxChest]) {

            if (result.status == "finished") {
                setOpen(false)
                setShadowOpen(false);

                setContentState("start")
                // if (ref.current)
                // ref.current.style.position = "";

            }
            if (result.status == "idle") {
                setOpen(false)
                setShadowOpen(false);
                setContentState("hide")
                // if (ref.current)
                //     ref.current.style.position = "";

            }
            if (result.status == "succeeded" && !open) {
                // const autoTimeout = setTimeout(() => {

                    if (result.payload.mines && result.payload.path && result.payload.path.includes(idxChest.toString()) && result.payload.mines.includes(idxChest.toString())) {
                        setShadowOpen(false);
                        setContentState("bomb");
                        sound.playBomb();
                        dispatch(openBomb());
                        requestAnimationFrame(() => {

                            try {
                                window.container[idxChest].list[0].tint = 0xffffff;
                                window.container[idxChest].list[1].alpha = 1;
                                window.container[idxChest].list.map((sym: any) => {

                                    sym.setTexture('chest-open');
                                });
                                const sym: any = window.add.spine(
                                    startX + (200 * (((idxChest) + 4) % 5)),
                                    startY + (190 * (idxRow)),
                                    "bomb",
                                    "play",
                                    false
                                );

                                // window.container[idxChest].add(sym);
                                sym.speed = 2;
                                // console.log(sym);
                                sym.addAnimation(0, "idle", false);

                                sym.scale = 1;


                                syms.push(sym);

                                // return () => {
                                //     syms.map((i) => i.destroy());
                                // }

                            } catch (error) {

                            }
                        })
                    }
                    if (result.payload.path && result.payload.path.includes(idxChest.toString()) && (!result.payload.mines || (result.payload.mines && !result.payload.mines.includes(idxChest.toString())))) {

                        setShadowOpen(false);
                        setContentState("diamond");
                        sound.playGem();
                        dispatch(countOpen(game.openCount + 1));
                        requestAnimationFrame(() => {
                            window.container[idxChest].list[0].tint = 0xffffff;
                            window.container[idxChest].list[1].alpha = 1;
                            window.container[idxChest].list.map((sym: any) => {

                                sym.setTexture('chest-open');
                            });
                            try {


                                const sym: any = window.add.spine(
                                    startX + (200 * (((idxChest) + 4) % 5)),
                                    startY + (190 * (idxRow)),
                                    randomSym,
                                    "play",
                                    false
                                );

                                sym.speed = 2;
                                sym.scale = 1;

                                // console.log(sym);
                                sym.addAnimation(0, "idle", false);
                                // sym.tint = 0x000000

                                syms.push(sym);

                                // return () => {
                                //     syms.map((i) => i.destroy());
                                // }

                            } catch (error) {

                            }
                        })

                    }
                    if (result.payload.mines && result.payload.path && !result.payload.path.includes(idxChest.toString()) && result.payload.mines.includes(idxChest.toString())) {
                        setShadowOpen(true);
                        setContentState("bomb");
                        requestAnimationFrame(() => {

                            try {

                                window.container[idxChest].list[0].tint = 0x000000;
                                window.container[idxChest].list[1].alpha = 0.55;
                                window.container[idxChest].list.map((sym: any) => {

                                    sym.setTexture('chest-open');
                                });

                                const container = window.container[idxChest];

                                const base_sym: any = () => {
                                    const sym = window.add.sprite(
                                        0, 0,
                                        "sym-bomb"
                                    );
                                    sym.scale = 0.1;
                                    return sym;
                                };

                                const sym = base_sym();
                                const copieSym = base_sym();
                                container.add(copieSym);
                                container.add(sym);

                                // console.log(sym);
                                copieSym.tint = 0x000000;
                                sym.alpha = 0.8;



                                syms.push(sym);
                                syms.push(copieSym);
                                // return () => {
                                //     syms.map((i) => i.destroy());
                                // }

                            } catch (error) {

                            }
                        })
                    }
                    if (result.payload.mines && result.payload.path && !result.payload.path.includes(idxChest.toString()) && !result.payload.mines.includes(idxChest.toString())) {

                        setShadowOpen(true);
                        setContentState("diamond");
                        requestAnimationFrame(() => {

                            try {

                                window.container[idxChest].list[0].tint = 0x000000;
                                window.container[idxChest].list[1].alpha = 0.55;
                                window.container[idxChest].list.map((sym: any) => {

                                    sym.setTexture('chest-open');
                                });

                                const container = window.container[idxChest];

                                // console.log(container);

                                const base_sym: any = () => {
                                    const sym = window.add.sprite(
                                        0, 0,
                                        randomSymDark
                                    );
                                    sym.scale = 0.1;
                                    return sym;
                                };

                                const sym = base_sym();
                                const copieSym = base_sym();
                                container.add(copieSym);
                                container.add(sym);
                                copieSym.tint = 0x000000;
                                sym.alpha = 0.8;

                                syms.push(sym);
                                syms.push(copieSym);
                                // return () => {
                                //     syms.map((i) => i.destroy());
                                // }

                            } catch (error) {

                            }
                        })
                    }
                    setOpen(true);
                // }, 50 * idxRow);
                return () => {
                    // clearTimeout(autoTimeout);
                };
            }

        }

        if (!dataProps && playerbet.currMode == "manual") {

            // console.log("manual");

            if (result.status == "finished") {
                setOpen(false)
                setShadowOpen(false);
                setContentState("start")
                // ref.current.style.position = "";

            }
            if (result.status == "idle") {
                setOpen(false)
                setShadowOpen(false);
                setContentState("hide")
                syms.map((i)=>{
                    i.destroy();
                })
                // ref.current.style.position = "";

            }
            if (result.status == "succeeded" && result.payload.path && !open) {
                if (result.payload.mines && result.payload.path.includes(idxChest.toString()) && result.payload.mines.includes(idxChest.toString())) {
                    setShadowOpen(false);
                    setContentState("bomb");
                    setOpen(true);
                    sound.playBomb();
                    dispatch(openBomb());
                    dispatch(stopBet());
                    requestAnimationFrame(() => {

                        try {
                            window.container[idxChest].list.map((sym: any) => {

                                sym.setTexture('chest-open');
                            });

                            const sym: any = window.add.spine(
                                startX + (200 * (((idxChest) + 4) % 5)),
                                startY + (190 * (idxRow)),
                                "bomb",
                                "play",
                                false
                            );
                            // window.container[idxChest].add(sym);

                            sym.speed = 2;
                            // console.log(sym);
                            sym.addAnimation(0, "idle", false);
                            sym.scale = 1.2;


                            syms.push(sym);

                            // return () => {
                            //     syms.map((i) => i.destroy());
                            // }

                        } catch (error) {
                            console.error(error)

                        }
                    })
                }

                if (result.payload.path.includes(idxChest.toString()) && (!result.payload.mines || (result.payload.mines && !result.payload.mines.includes(idxChest.toString())))) {
                    if (!result.payload.mines && game.openCount + 1 == (25 - playerbet.manual.mines) && ["hide", "start"].includes(contentState)) {
                        handleCashOut();
                    }
                    setShadowOpen(false);
                    setContentState("diamond");
                    sound.playGem();
                    dispatch(countOpen(result.payload.path.length - (result.payload.mines || []).length));
                    setOpen(true);
                    requestAnimationFrame(() => {

                        try {
                            window.container[idxChest].list.map((sym: any) => {

                                sym.setTexture('chest-open');
                            });


                            const sym: any = window.add.spine(
                                startX + (200 * (((idxChest) + 4) % 5)),
                                startY + (190 * (idxRow)),
                                randomSym,
                                "play",
                                false
                            );

                            // window.container[idxChest].add(sym);
                            sym.speed = 2;
                            // console.log(sym);
                            // sym.addAnimation("idle");
                            sym.scale = 1.2;


                            syms.push(sym);

                            // return () => {
                            //     syms.map((i) => i.destroy());
                            // }

                        } catch (error) {
                            console.error(error)
                        }
                    })

                }

                if (result.payload.mines && !result.payload.path.includes(idxChest.toString()) && result.payload.mines.includes(idxChest.toString())) {
                    setShadowOpen(true);
                    setContentState("bomb");
                    setOpen(true);
                    requestAnimationFrame(() => {

                        try {
                            window.container[idxChest].list[0].tint = 0x000000;
                            window.container[idxChest].list[1].alpha = 0.55;
                            window.container[idxChest].list.map((sym: any) => {

                                sym.setTexture('chest-open');
                            });

                            const container = window.container[idxChest];

                            const base_sym: any = () => {
                                const sym = window.add.sprite(
                                    0, 0,
                                    "sym-bomb"
                                );
                                sym.scale = 0.1;
                                return sym;
                            };

                            const sym = base_sym();
                            const copieSym = base_sym();
                            container.add(copieSym);
                            container.add(sym);

                            // console.log(sym);
                            copieSym.tint = 0x000000;
                            sym.alpha = 0.8;

                            syms.push(sym);
                            syms.push(copieSym);
                            // return () => {
                            //     syms.map((i) => i.destroy());
                            // }

                        } catch (error) {

                        }
                    })


                }
                if (result.payload.mines && !result.payload.path.includes(idxChest.toString()) && !result.payload.mines.includes(idxChest.toString())) {
                    setShadowOpen(true);
                    setContentState("diamond");
                    setOpen(true);

                    requestAnimationFrame(() => {

                        try {
                            window.container[idxChest].list[0].tint = 0x000000;
                            window.container[idxChest].list[1].alpha = 0.55;
                            window.container[idxChest].list.map((sym: any) => {

                                sym.setTexture('chest-open');
                            });

                            const container = window.container[idxChest];

                            // console.log(container);

                            const base_sym: any = () => {
                                const sym = window.add.sprite(
                                    0, 0,
                                    randomSymDark
                                );
                                sym.scale = 0.1;
                                return sym;
                            };

                            const sym = base_sym();
                            const copieSym = base_sym();
                            container.add(copieSym);
                            container.add(sym);
                            copieSym.tint = 0x000000;
                            sym.alpha = 0.8;
                            syms.push(sym);
                            syms.push(copieSym);

                            // return () => {
                            //     syms.map((i) => i.destroy());
                            // }

                        } catch (error) {

                        }
                    })

                }
            }
        }

        // })

    }, [result.status, window.container && window.container[idxChest], syms.length]);




    useEffect(() => {


        // if (window.add && contentState == "start") {
        //     try {

        //         // const container1 = window.add.container(400, 300);
        //         const sym: any = window.add.sprite(
        //             startX + (200 * (((idxChest) + 5) % 5)),
        //             startY + (190 * (idxRow)),
        //             "chest-close"
        //         );

        //         // console.log(sym);

        //         sym.scale = 0.160;

        //         sym.removeAllListeners();
        //         sym.setInteractive();

        //         sym.on(
        //             "pointerdown",
        //             () => {
        //                 //
        //                 // console.log(idxChest);

        //                 sym.setTexture('chest-open')
        //                 handleClick(idxChest);
        //             }
        //         );

        //         syms.push(sym);
        //         return () => {
        //             syms.map((i) => i.destroy());
        //         }

        //     } catch (error) {

        //     }


        // }

        if (window.add && (contentState == "start" || contentState == "hide")) {
            // console.log(idxChest)

            if (window.container && window.container[idxChest]) {

                // window.container[idxChest].list.map((i: any) => i.destroy());
                // window.container[idxChest].destroy();
            }

            try {
                const container = window.add.container(startX + (200 * (((idxChest) + 4) % 5)),
                    startY + (190 * (idxRow)));

                // console.log(container);

                const base_sym: any = () => {
                    const sym = window.add.sprite(
                        0, 0,
                        "chest-close"
                    );
                    sym.scale = 0.160;
                    return sym;
                };

                const sym = base_sym();
                const copieSym = base_sym();
                container.add(copieSym);
                container.add(sym);

                // console.log(sym);

                sym.alpha = 0.8;

                sym.removeAllListeners();
                sym.setInteractive();
                sym.on(
                    "pointerdown",
                    (e: any) => {
                        //
                        // console.log(idxChest);


                        handleClick(idxChest);
                    }
                );
                container.click = () => handleClick(idxChest);
                if (window.container[idxChest]) {
                    window.container[idxChest].destroy();
                }
                window.container[idxChest] = container;
                // console.log("renew chest")
                if (selected) {
                    window.container[idxChest].list[0].tint = 0x000000;
                    window.container[idxChest].list[1].alpha = 0.55;
                }
                // syms.push(container);
                // return () => {
                //     syms.map((i) => i.destroy());
                // }

            } catch (error) {

            }


        }
        // console.log(contentState, "contentstate");



    }, [window.add, contentState, handleClick]);

    return <></>;
    return <div key={`${dataProps ? "history" : "main"}-col-box-${idxChest}`} className="col">
        {/* <div data-idxchest={idxChest} className={"chest chest-".concat(idxChest.toString()).concat(" ").concat(selected && !open ? " selected " : "").concat(!dataProps && selected && open && !shadowOpen ? " open " : "").concat(((!dataProps && !selected) || dataProps) && open && shadowOpen ? " shadowOpen " : "")} onClick={handleClick} > */}
        {/* <img className="box" src={open ? ChestOpen : ChestClose} alt="box"  {...{ ...defaultImageProps }} /> */}

        {/* {!["hide", "start"].includes(contentState) ? <Content {...{ contentState, sym, shadowOpen }} /> : <></>} */}
        {/* </div> */}
    </div>
}

// interface ContentIProps {
//     contentState: ContentStateType;
//     shadowOpen: boolean
// }

// const Content: React.FC<ContentIProps> = ({ contentState, sym, shadowOpen }) => {

//     const src = contentState == "bomb" ? Bomb : images[sym];
//     const ref: any = useRef(null);
//     useLayoutEffect(() => {
//         try {
//             if (ref.current) {

//                 const obj = { curImg: 1 };
//                 if (contentState == "bomb" && shadowOpen) {
//                     ref.current!.src = Bomb[0];

//                 }

//                 const tween = gsap.to(obj, 1,
//                     {
//                         curImg: src.length - 1,	// animate propery curImg to number of images
//                         roundProps: "curImg",				// only integers so it can be used as an array index
//                         repeat: contentState != "bomb" ? 0 : Infinity,
//                         immediateRender: true,			// load first image automatically
//                         ease: Linear.easeInOut,			// show every image the same ammount of time
//                         onStart: function () {
//                             if (ref.current) {

//                                 ref.current.previousSibling.style.position = "absolute";
//                             }
//                         },
//                         onUpdate: function () {
//                             if (ref.current) {
//                                 if (contentState == "bomb" && shadowOpen) {

//                                 } else {
//                                     if (shadowOpen) {

//                                         ref.current!.src = src[src.length - 1];
//                                     } else {

//                                         ref.current!.src = src[obj.curImg];
//                                     }

//                                 }
//                             }
//                         },
//                         onComplete: function () {

//                         }

//                     }
//                 );
//                 return () => {
//                     tween.kill()
//                 }

//             }
//         } catch (error) {

//         }
//     }, []);

//     return <>
//         <img ref={ref} className="content" src={""} alt="content"  {...{ ...defaultImageProps }} />
//         {/* {contentState == "bomb" ? <img className="content bomb" src={Bomb[0]} alt="content"   {...{ ...defaultImageProps }} /> : <></>} */}
//     </>
// }