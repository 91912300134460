import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import BGDropdown from "./dropdown.png";
import { motion } from "framer-motion";
import BGInput12 from "./../bg-input-12.webp";
import { useAppSelector } from "../../../store/hook";

interface DropDownIProps {
  bg?: string;
  toolicon?: string;
  label: string;
  options: string[] | number[];
  setSelected?: (value: string|number) => void;
  style?: CSSProperties;
  className?: string;
  defaultValue?: string | number;
  disabled?: boolean;
}


function useOutsideClick(
  ref: any,
  onClickOut: () => void,
  arrayDeps: any[]
) {
  useEffect(() => {
    const onClick = ({ target }: any) =>{
      ref && !ref.contains(target) && onClickOut();
    }
    document.addEventListener("click", onClick);
    return () => {
      document.removeEventListener("click", onClick);
    }
  }, [arrayDeps]);
}

export function useAnimateOpening(display: string){

  const [active, setActive] = useState<boolean|number>(false);
  const [opening, setOpening] = useState<boolean>(false);

const toggleDropDown = {
  open: {
    opacity: 1,
    display,
    // height:'auto',
    transition: {
      duration: 0.5,
    },
  },
  close: {
    opacity: 0,
    display: 'none',
    // height: "0",
    transition: {
      duration: 0.3,
    },
  },
};
  const containerRef = useRef();
  useOutsideClick(
    containerRef.current!,
    () => {
      if (active && !opening) setActive(false);
    },
    [active, opening, containerRef.current]
  );
  const handleOpen = (onClose: any =false, activeValue: boolean|number=false) => {
    if(!opening && active===false){
        setOpening(true);
        setActive(activeValue===false?true: activeValue);
    }else {
      onClose && onClose()
      setOpening(false);
        setActive(false);
    }
  };

  const handleClose = (onClose: any) => {
    onClose && onClose()
      setOpening(false);
        setActive(false);
    }

  useEffect(() => {
    if (opening) {
      setTimeout(() => {
        setOpening(false);
      }, 500);
    }
  }, [opening]);
  return {handleOpen,handleClose, active, setActive, opening, containerRef, motionVariant: toggleDropDown, open:(idx: number)=>{
    // handleClose()
    setOpening(true);
    setActive(idx);
  }}
}

const DropDown = ({ toolicon, bg, label, options, setSelected, style, className, defaultValue, disabled=false }: DropDownIProps) => {

  const defaultSelected = defaultValue || options[0];
  const [ selected, setInternalSelected ] = useState(defaultSelected);
  const [ search, setSearch ] = useState(defaultSelected.toString());
  const { handleOpen, active,setActive, opening, containerRef, motionVariant, handleClose} = useAnimateOpening('block');
  const playerbet = useAppSelector((state) => state.playerbet);



  const handleSelect = (option: string|number) => {
    if(disabled) return;
    setSelected && setSelected(option);
    setInternalSelected(option);
    setSearch(option+"");
    handleOpen();
  };

  useEffect(()=>{
    setSelected && setSelected(defaultSelected);
  }, [playerbet.currMode]);

  
  return (
    <div className={"form-group form-group-dropdown ".concat(className||"")} style={{ ...style }}>
        <img
          className="bet-bg-input"
          alt=""
          src={bg||BGInput12}
        />  
      <label htmlFor={label}>{label}</label>
      <input
        type="text"
        id={label}
        name={label}
        value={search}
        onFocus={({target, preventDefault})=>{
          // preventDefault()
          target.blur()
          // if(!active)
          // setActive(true);
          handleOpen()

        }}
        onKeyDownCapture={(e) => {
          if((options as any).every((i: string|number)=>!Number.isNaN(i))){
          var charTyped = e.key;
          var letterRegex = /[^0-9]/;
          if (!charTyped.match(letterRegex)) {
            // @ts-ignore
            if (e.target.value.split(".")[1] && e.target.value.split(".")[1].length >= 2 && window.getSelection().getRangeAt(0).startOffset == e.target.value.length) {
              return e.preventDefault();
            }
          }
          else if (e.key == "Backspace") {
          }
          else if (e.key.indexOf("Arrow") > -1) {
          }
          else if (e.key == ".") {
            // @ts-ignore
            if (e.target.value.indexOf(".") > 0) {
              return e.preventDefault();
            }
          }
          else {
            return e.preventDefault();
          }}
        }}
        onBlur={(e: any)=>{
          // @ts-ignore
          if(options.map(i=>i.toString()).indexOf(e.target.value)>-1){
            setSearch(e.target.value)
          }else{
          setSearch("")
          }
        }}
        onChange={(e)=>{
          setSearch(e.target.value)
          // handleOpen()
          // if(!active&&!opening)
          setActive(true);
          //@ts-ignore
          containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        disabled={disabled}
      />
      <div className={"bet-form-tool-list dropdown ".concat(disabled?'disabled':'')}>
        <div className="tool-item" >
          <img onContextMenu={(e)=>e.preventDefault()} draggable="false" className="tool-bg-dropdown" alt="" src={toolicon||BGDropdown} onClick={handleOpen}/>
        </div>
      </div>

      {/* <span ref={ref}> */}
      <motion.div
        // @ts-ignore
        ref={containerRef}
        className="dropdown-select"
        initial="close"
        animate={active && !disabled ? "open" : "close"}
        variants={motionVariant}
      >
        {[...options].filter(onlyUnique).map((option: any, idx: number) => {
          return (
            <div
            key={'option-'.concat(idx.toString())} onClick={() => handleSelect(option)} className="select-item">
              <span>{option}</span>
            </div>
          );
        })}
      </motion.div>
      {/* </span> */}
    </div>
  );
};

export default DropDown;
function onlyUnique(value: any, index: number, array: any[]) {
  return array.indexOf(value) === index;
}