import "./index.scss";
import { useEffect, useRef, useState } from "react";
import Bomb from "./animation/Symbol Bomb";
import Diamond1 from "./animation/Symbol 1";
import Diamond2 from "./animation/Symbol 2";
import Diamond3 from "./animation/Symbol 3";
import Diamond4 from "./animation/Symbol 4";
import Diamond5 from "./animation/Symbol 5";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { selectToOpen } from "./reducer";
import { ResultFull, SendBet } from "../result/type";
import { sendBetAction, changeSelectChest } from "../result/reducer";
import { ASSETS_IMAGE_CACHED } from "../hook/PreloadImage";
import { useTranslation } from "react-i18next";
import { getPlayer } from "../user/auth/reducer";
import { Player } from "../user/auth/type";
import { formatCurrency } from "../../tool/money";
import { Chest } from "./chest";
import useOrientation from "../hook/Orientation";
import sound from "../../sound";
import { Chest2 } from "./chest2";

interface GameIProps {
  dataProps?: null | { payload: ResultFull };
  idx?: number;
}

declare global {
  interface Window {
    add: any;
    container: any
    Phaser: any;
    SpinePlugin: any;
    PhaserLoaderPlugin: any;
    SpineWebGLPlugin: any;
    currPortalLevel: number;
    game: any;
    gray: any;
    bgProfit: any;
    textProfit: any;
    timeoutProfit: any;
    // openPortal: ((newPortal: "open-win-" | "open-lose-", i?: any) => void) | null
    sweepPortal: (sendBet: SendBet, idxPortal: number) => void;
    handleCashOut: () => void;
  }
}
let phaserElement: any;

const Game: React.FC<GameIProps> = (
  { dataProps, idx } = { dataProps: undefined, idx: -1 }
) => {
  const [selectedChest, setSelectedChest] = useState<{
    [index: string]: number;
  }>({});
  const dispatch = useAppDispatch();
  const playerbet = useAppSelector((state) => state.playerbet);
  // @ts-ignore
  const ref: {
    current: HTMLDivElement;
  } = useRef(null);
  const game = useAppSelector((state) => state.game);
  const result = useAppSelector((state) => state.result);
  const history: any = {}
  history.current_round = useAppSelector((state) => state.history.current_round);
  let renderTimeout: any;
  // console.log("rerender game")

  useEffect(() => {
    setSelectedChest({});
  }, [playerbet.currMode]);

  useEffect(() => {
    if (!dataProps) {
      dispatch(changeSelectChest());
      dispatch(selectToOpen(Object.values(selectedChest)));
    }
  }, [selectedChest]);

  const player = useAppSelector(getPlayer) as Player;
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const { player_currency } = player;

  const handleFormatCurrency = (amount: number): string => {
    return formatCurrency(amount, player_currency, language);
  };

  const handleClick = (idxChest: number) => {
    if (!idxChest) return;
    // console.log("handleClick", dataProps,
    //   playerbet.currMode == "auto", playerbet[playerbet.currMode].start);

    if (
      dataProps ||
      (playerbet.currMode == "auto" && playerbet[playerbet.currMode].start)
    )
      return;
    // console.log("handleClick");

    if (playerbet.currMode == "auto") {
   
      setSelectedChest((old: any) => {
        let newSelected;
        if (!Object.values(old).includes(idxChest)) {
          if (Object.keys(old).length == 25 - playerbet.auto.mines) return old;
          newSelected = { ...old, [Object.keys(old).length]: idxChest };
          
          // window.container[idxChest].list[0].tint = 0xffffff;
          // window.container[idxChest].list[1].alpha = 1;
        } else {
          newSelected = {
            ...Object.values(old).filter(
              (idxSelected) => idxSelected != idxChest
            ),
          };
        }

      
        return newSelected;
      });
      // console.log(idxChest, "select auto");

      return;
    } else {

      // console.log(window.container[idxChest]);

      // console.log("manual", game.isOpenBomb,
      //   playerbet.manual.start);
      if (
        // !e.currentTarget.querySelector(".content") &&
        !game.isOpenBomb &&
        playerbet.manual.start
      ) {
        if (game.openCount == 25 - playerbet.manual.mines) return;
        // console.log("sweep")
        

        const sendBet: SendBet = {
          game: "GH000001",
          total_amount: playerbet[playerbet.currMode].amount,
          button_bet: {
            amount: playerbet[playerbet.currMode].amount,
            mines: playerbet[playerbet.currMode].mines,
            path: idxChest + "",
            round_id: history.current_round,
            state: "sweep",
          },
          round_id: history.current_round,
        };
        if (
          result.payload.last_bet.button_bet.state == "sweep" &&
          result.payload.last_bet.button_bet.path == idxChest + ""
        ) {
        } else {
          if (result.status !== "loading") dispatch(sendBetAction(sendBet));
        }
      }
    }
  };

  const orientation = useOrientation();

  useEffect(() => {
    if (
      (playerbet.currMode == "auto" &&
        result.status == "succeeded" &&
        result.payload &&
        result.payload.prize) ||
      (playerbet.currMode == "manual" &&
        result.status == "succeeded" &&
        result.payload.last_bet.button_bet.state == "cashout")
    ) {


      // if(playerbet.currMode == 'manual' && window.textProfit && window.bgProfit){
      //   sound.muteWin(true);
      //   window.textProfit.destroy();
      //   window.bgProfit.destroy();
      //   window.bgProfit = undefined;
      //   window.textProfit = undefined;
      // }

      if (window.bgProfit && window.bgProfit.destroy)
        // window.bgProfit.destroy();
        return () => { }
      if (window.textProfit && window.textProfit.destroy)
        // window.textProfit.destroy();
        return () => { }

      window.bgProfit = window.add.spine(
        orientation == "landscape" ? 490 : 490,
        orientation == "landscape" ? 700 : 700,
        "total",
        "play",
        false
      );

      window.textProfit = window.add.text(
        orientation == "landscape" ? 490 : 490,
        orientation == "landscape" ? 840 : 840,
        handleFormatCurrency(
          result.payload.prize * result.payload.last_bet.button_bet.amount
        ).toString(),
        {
          fontSize: "48px",
          fill: "#2c2214",
          fontWeight: "bold"
        }
      );

      if(orientation == "portrait"){
        window.bgProfit.scale = 1.05;
        window.textProfit.scale = 1.1;
        window.textProfit.y = 850;
      }

      window.textProfit.setOrigin(0.5);
      // window.textProfit.setDepth(2);
      window.textProfit.setAlpha(0);
      window.bgProfit.setDepth(100);
      window.textProfit.setDepth(101);
      sound.muteWin(false);
      sound.playWin();
      window.timeoutProfit = setTimeout(() => {
        sound.muteWin(true);
        window.textProfit.destroy();
        window.bgProfit.destroy();
        window.bgProfit = undefined;
        window.textProfit = undefined;
      }, window.bgProfit.state.tracks[window.bgProfit.state.tracks.length - 1].animation.duration * 1000);
      setTimeout(() => {
        if (window.textProfit && window.textProfit.setAlpha)
          window.textProfit.setAlpha(1);
      }, 1600)

      return () => {
        if (playerbet.currMode == 'manual' && window.textProfit && window.bgProfit) {
          sound.muteWin(true);
          window.textProfit.destroy();
          window.bgProfit.destroy();
          window.bgProfit = undefined;
          window.textProfit = undefined;
        }
      };
    }
  }, [

    orientation,
    (playerbet.currMode == "auto" &&
      result.status == "succeeded" &&
      result.payload &&
      result.payload.prize) ||
    (playerbet.currMode == "manual" &&
      result.status == "succeeded" &&
      result.payload.last_bet.button_bet.state == "cashout"),
  ]);

  // Move phaser canvas here
  useEffect(() => {
    if (document.getElementById("phaser")) {
      //    console.log("console remove", document.getElementById('phaser'))

      phaserElement = document.getElementById("phaser");
    }

    if (phaserElement) {
      console.log("console add", phaserElement);
      (
        document.querySelector(
          ".game-container.main .total-profit-auto"
        )! as HTMLElement
      ).appendChild(phaserElement);

      if (!window.container) {
        window.container = {};
      }
      // return ()=>{
      //     if(document.getElementById('phaser')){
      //    console.log("console remove", document.getElementById('phaser'))

      //      phaserElement = document.getElementById('phaser');
      //     }
      //     (ref.current.firstChild as HTMLElement).removeChild(phaserElement)
      // }
    }
  }, []);

  const ChestUsed = dataProps ? Chest2 : Chest;

  return (
    <div
      ref={ref}
      className={"game-container ".concat(
        `${dataProps ? "game-history-table" : "main"}`
      )}
    >
      <div className="game-wrapper">
        {Array.from(Array(5).keys()).map((idxRow) => {
          return (
            <div
              key={`${dataProps ? "history" : "main"}-row-${idxRow}-${playerbet.currMode
                }`}
              className="row"
            >
              {Array.from(Array(5).keys()).map((idxCol) => {
                const idxChest = idxRow * 5 + idxCol + 1;

                return (
                  <ChestUsed
                    key={`${dataProps ? "history" : "main"
                      }-col-box-${idxChest} `}
                    {...{
                      idxChest,
                      dataProps,
                      handleClick,
                      selected: game.selected.includes(idxChest),
                      idxRow,
                    }}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
      <div
        className="total-profit-auto"
      // style={{
      //   display:
      //     (playerbet.currMode == "auto" &&
      //       result.status == "succeeded" &&
      //       result.payload &&
      //       result.payload.prize) ||
      //       (playerbet.currMode == "manual" &&
      //         result.status == "succeeded" &&
      //         result.payload.last_bet.button_bet.state == "cashout")
      //       ? "flex"
      //       : "none",
      // }}
      >
        {/* <img onContextMenu={(e) => e.preventDefault()} draggable="false" className='bg-prize' src={TotalPrize} alt="" /> */}
        {/* <span>
          {handleFormatCurrency(
            result.payload.prize * result.payload.last_bet.total_amount
          )}
        </span> */}
      </div>
    </div>
  );
};

export default Game;

export const images = { Diamond1, Diamond2, Diamond3, Diamond4, Diamond5 };

export const randomProperty: (T: typeof images) => keyof typeof images = (
  obj
) => {
  const keys = Object.keys(obj);
  return keys[(keys.length * Math.random()) << 0] as keyof typeof images;
};
export const cachedDataImage = (url: any) => {
  return new Promise<string>(async (callback) => {
    // if (document.querySelector('.safari.iPhone'))
    return callback(url);

    caches.open(ASSETS_IMAGE_CACHED).then((cache) => {
      cache.match(url).then(async (data) => {
        if (!data) return callback(url);
        let reader = new FileReader();
        reader.onload = function () {
          callback(this.result as string);
        };
        reader.readAsDataURL(await data!.blob());
      });
    });
  });
};
