let timerId: any;
function debounce(callback: () => void, wait: number) {
  return () => {
      if(timerId) {
        
      }else{
          timerId = setTimeout(() => {
              clearTimeout(timerId);
              timerId = undefined;
            }, wait);
            callback();
      }
    };
}

export { debounce };
