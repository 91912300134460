import bgm from "./bgm.mp3";
import bomb1 from "./bomb-1.mp3";
import bomb2 from "./bomb-2.mp3";
import gem1 from "./gem-1.mp3";
import gem2 from "./gem-2.mp3";
import gem3 from "./gem-3.mp3";
import totalwin from "./totalwin.mp3";

const samplePaths = [ bomb1, bomb2, gem1, gem2, gem3, totalwin];

class Sound {
  bgMusic!: HTMLAudioElement;

  private _enableSfx: boolean = true;
  private _volumeSfx: number = 100;

  private _enableMusic: boolean = false;
  private _volumeMusic: number = 100;

  samples: any[] = [];
  audioContext = new AudioContext();
  bgmPlaying: any;

  constructor() {
    this.bgMusic = new Audio(bgm);
  }

  async getFile(filePath: string) {
    const response = await fetch(filePath);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
    return audioBuffer;
  }

  async setupSamples(paths: string[]) {
    // console.log("Setting up samples");

    for (const path of paths) {
      const sample = await this.getFile(path);
      this.samples.push(sample);
    }
  }

  playSample(audioBuffer: any, time: number, gain: GainNode) {
    const sampleSource = this.audioContext.createBufferSource();
    sampleSource.buffer = audioBuffer;
    sampleSource.connect(gain);
    sampleSource.start(time);
    gain.connect(this.audioContext.destination);
    return sampleSource;
  }

  async playBgMusic() {
    if (!this._enableMusic) {
      return (this.bgmGain.gain.value = 0);
    }

    try {
      if (!this.bgmPlaying) {
        this.bgmPlaying = this.playSample(await this.getFile(bgm), 0, this.bgmGain);
        this.bgmPlaying.loop = true;
      } else this.bgmGain.gain.value = this._volumeMusic;
    } catch (ex) {
      console.warn(ex);
    }
  }

  playGem() {
    // this.audioContext.resume();
    if (!this._enableSfx) {
      this.sfxGain.gain.value = 0;
      return;
    }

    try {
      this.sfxGain.gain.value = this.volumeSfx;
      this.playSample(
        this.samples[Number(randomNumber(0, 2).toFixed()) + 2],
        0,
        this.sfxGain
      );
    } catch (ex) {
      console.warn(ex);
    }
  }

  playBomb() {
    if (!this._enableSfx) {
      this.sfxGain.gain.value = 0;
      return;
    }
    try {
      this.sfxGain.gain.value = this.volumeSfx;
      this.playSample(
        this.samples[Number(randomNumber(0, 1).toFixed()) ],
        0,
        this.sfxGain
      );
    } catch (ex) {
      console.warn(ex);
    }
  }

  playWin() {
    if (!this._enableSfx) {
      this.winGain.gain.value = 0;
      return;
    }
    try {
      this.winGain.gain.value = Number(this.sfxGain.gain.value)/2;
      this.playSample(this.samples[5], 0, this.winGain);
    } catch (ex) {
      console.warn(ex);
    }
  }

  stopBgMusic() {
    this.bgmGain.gain.value = 0;
  }

  bgmGain = new GainNode(this.audioContext, {
    gain: 1,
  });
  sfxGain = new GainNode(this.audioContext, {
    gain: 1,
  });

  winGain = new GainNode(this.audioContext, {
    gain: 1,
  });

  updateVolumeMusic(volume: number) {
    if(this._enableMusic){
      this.bgmGain.gain.value = volume / 100;
    }

    this.volumeMusic = volume / 100;

    // this.bgMusic.volume = (volume / 100) * 0.25
  }

  updateVolumeSfx(volume: number) {
    if(this._enableSfx){
      this.sfxGain.gain.value = volume / 100;
      this.winGain.gain.value = Number(this.sfxGain.gain.value)/2;
    }
    this.volumeSfx = volume / 100;
  }

  muteWin(bool: boolean) {
    if(bool){
        this.winGain.gain.value = 0;
    }else{
        this.winGain.gain.value = Number(this.sfxGain.gain.value)/2;
    }
  }

  playSound(soundObj: HTMLAudioElement, soundVolume: number) {
    if (!soundObj || isNaN(soundVolume)) return;

    try {
      soundObj.volume = soundVolume / 100;
      const playPromise = soundObj.play();

      if (playPromise) {
        playPromise
          .then(() => {
            // Automatic playback
          })
          .catch((err) => {
            console.error(err);
          });
      }
    } catch (ex) {
      console.warn(ex);
    }
  }
  /** getter & setter */
  enableSfx(isEnable: boolean) {
    this._enableSfx = isEnable;
    if(isEnable){
      this.sfxGain.gain.value = this._volumeSfx;
      this.winGain.gain.value = Number(this.sfxGain.gain.value)/2;
    }else{
      this.sfxGain.gain.value = 0;
      this.winGain.gain.value = 0;
    }
  }

  get volumeSfx() {
    return this._volumeSfx;
  }

  set volumeSfx(volume: number) {
    this._volumeSfx = volume;
  }

  enableMusic(isEnable: boolean) {
    this._enableMusic = isEnable;
    if(isEnable){
      this.bgmGain.gain.value = this._volumeMusic;
    }else{
      this.bgmGain.gain.value = 0;
    }
  }

  get volumeMusic() {
    return this._volumeMusic;
  }

  set volumeMusic(volume: number) {
    this._volumeMusic = volume;
  }
}

const sound = new Sound();
sound.setupSamples(samplePaths);

export default sound;

const randomNumber = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};
