import React from 'react'
// @ts-ignore
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import './lang/i18n'
import store from './store'
import './index.scss'
import App from './ui/app'
import { fetchHistory } from './ui/history/reducer'
import { authLogin } from './ui/user/auth/reducer'

async function main() {

	store.dispatch(authLogin())
	store.dispatch(fetchHistory())
  
	ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
		<React.StrictMode>
			<Provider store={store}>
				<App />
			</Provider>
		</React.StrictMode>
	)
}

main()
