
import Bom from './diamond.webp'
import Icon from './Symbol_3.webp'


const Dimaond3 = [
    Icon,
    Bom,

];

export default  Dimaond3