
import Bom from './diamond.webp'
import Icon from './Symbol_1.webp'

const Dimaond1 = [
    Icon,
    Bom,
 
];

export default  Dimaond1