import { configureStore } from "@reduxjs/toolkit"

// import testReducer from "./TestStore"
import authReducer from "../ui/user/auth/reducer"
import playerbetReducer from '../ui/betbar/reducer'
import resultReducer from '../ui/result/reducer'
import gameReducer from '../ui/game/reducer'
import historyReducer from '../ui/history/reducer'
import { createLogger } from 'redux-logger'

const logger: any = createLogger({
    // ...options
});

const store = configureStore({
    reducer: {
        game: gameReducer,
        auth: authReducer,
        history: historyReducer,
        result: resultReducer,
        playerbet: playerbetReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // serializableCheck: true,
        })
        // .concat(logger),
    // devTools: true

})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
