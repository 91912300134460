import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const initialState: any = {
    openCount: 0,
    selected: [],
    isOpenBomb: false

}


export const game = createSlice({
    name: 'game',
    initialState,
    reducers: {
       
        countOpen: (state, action: PayloadAction<number>) => {
            state.openCount = action.payload
        },
        selectToOpen: (state, action: PayloadAction<number[]>) => {
            state.selected = [...action.payload]
        },
        openBomb: (state, action: PayloadAction) => {
            state.isOpenBomb = true;
        },
        restart: (state, action: PayloadAction) => {
            state.isOpenBomb = false;
            state.selected = [];
            state.openCount = 0;
        },
        restartAuto: (state, action: PayloadAction) => {
            state.isOpenBomb = false;
            state.openCount = 0;
        },
    }
})


export const {
    countOpen,
    selectToOpen,
    openBomb,
    restart,
    restartAuto
} = game.actions


export default game.reducer