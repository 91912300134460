
import Bom from './diamond.webp'
import Icon from './Symbol_4.webp'


const Dimaond4 = [
    Icon,
    Bom,

];

export default  Dimaond4