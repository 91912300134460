import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { BetBarState, BetMode, LastBet } from "./type";

const initialState: BetBarState = {

    last_bet: null,
    manual: {
        round_id: 0,
        amount: 0,
        mines: 1,
        gems: 1,
        total_profit: 0,
        start: false,
    },
    auto: {
        round_id: 0,
        amount: 0,
        mines: 1,
        total_profit: 0,
        mines_list: Array.from(Array(24).keys()).map((i)=>i+1),
        start: false,
        numberOfBet: Infinity
    },
    currMode: "manual" 
}


export const betbarSlice = createSlice({
    name: 'betbar',
    initialState,
    reducers: {
        changeList: (state, action: PayloadAction<any>) => {
            state.auto.mines_list = action.payload.mines_list || state.auto.mines_list
            // state.risk_list = action.payload.risk_list || state.risk_list
            // state.row_list = action.payload.row_list || state.row_list
        },

        changeMines: (state, action: PayloadAction<{mode: keyof BetMode,value:number}>) => {
            state[action.payload.mode].mines = action.payload.value
        },

        changeMode: (state, action: PayloadAction<BetBarState["currMode"]>) => {
            state.currMode = action.payload
        },

        changeNumOfBet: (state, action: PayloadAction<number>) => {
            if(typeof action.payload=="number" && state.auto.numberOfBet !==action.payload)
            state.auto.numberOfBet = action.payload;
        },
      
        changeAmount: (state, action: PayloadAction<number>) => {
            if(state[state.currMode].amount !== action.payload){
                state[state.currMode].amount = action.payload;
            }
 
        },

        startBet: (state, action: PayloadAction) => {
            state[state.currMode].start = true;
        },
        stopBet: (state, action: PayloadAction) => {
            state[state.currMode].start = false;
            
        },
        updateTotalProfit: (state, action: PayloadAction<number>) => {
            // @ts-ignore
            state[state.currMode].total_profit = action.payload;
            
        },
        updateTotalAmountAuto: (state, action: PayloadAction<number>) => {
            
            // if(!action.payload)
            // state.total_amount_auto = 0
            // else state.total_amount_auto += action.payload

            
        },
        setLastBet: (state, action: PayloadAction<LastBet[]>) => {
            const betArr = action.payload

            betArr.forEach(bet => {
                // state.last_bet.push(bet)
            })

        },
    }
})


export const {
 setLastBet,
 changeAmount,
 changeMines,
 stopBet,
 changeList,
 changeMode,
 updateTotalAmountAuto,
 startBet,
 updateTotalProfit,
 changeNumOfBet,
} = betbarSlice.actions

export const getTotalBets = (state: RootState) => 0

export default betbarSlice.reducer